// Maps actions
export const	MAPS_INIT_IDLE = 'MAPS/INIT_IDLE'
export const 	MAPS_INIT_RESET = 'MAPS/INIT_RESET'
export const	MAPS_INIT_START = 'MAPS/INIT_START'
export const	MAPS_INIT_DONE = 'MAPS/INIT_DONE'
export const	MAPS_INIT_FAIL = 'MAPS/INIT_FAIL'
export const SET_MAP_LISTENERS = 'MAPS/SET_MAP_LISTENERS'
export const UNSET_MAP_LISTENERS = 'MAPS/UNSET_MAP_LISTENERS'
export const SET_MAP_CENTER = 'MAPS/SET_MAP_CENTER'

// Common search
export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS'
export const SET_BUILDINGTYPES = 'SET_BUILDINGTYPES'

// Adress Search
export const SET_SEARCH_ADDRESS = 'MAPS/SET_SEARCH_ADDRESS'
export const SET_SEARCH_MARKER = 'MAPS/SET_SEARCH_MARKER'
export const UNSET_SEARCH_MARKER = 'MAPS/UNSET_SEARCH_MARKER'

// Polygon actions
export const CREATE_POLYGON = 'MAPS/CREATE_POLYGON'
export const DELETE_POLYGON = 'MAPS/DELETE_POLYGON'
export const SET_POLYGON_ACTIVE = 'MAPS/SET_POLYGON_ACTIVE'
export const SET_POLYGON_INACTIVE = 'MAPS/SET_POLYGON_INACTIVE'
export const SET_POLYGON_SUSPENDED = 'MAPS/SET_POLYGON_SUSPENDED'
export const ADD_POLYGON_VERTEX = 'MAPS/ADD_POLYGON_VERTEX'
export const SET_POLYGON_PROPERTIES = 'MAPS/SET_POLYGON_PROPERTIES'

// Path
export const SET_PATH_ENABLED = 'MAPS/SET_PATH_ENABLED'
export const SET_PATH_DISABLED = 'MAPS/SET_PATH_DISABLED'

// Focus Areas
export const BUILDING_TYPE_DEFAULT = '0'
export const BUILDING_TYPE_OTHER = '1'
export const BUILDING_TYPE_TENANCY = '512'
export const BUILDING_TYPE_CONDO = '1504'
export const BUILDING_TYPE_HOUSE = '30'
export const BUILDING_TYPE_COMBO = '1534'
export const TOGGLE_FOCUS_AREAS = 'MAPS/TOGGLE_FOCUS_AREAS'
export const SET_FOCUS_AREA_FEATURE = 'MAPS/SET_FOCUS_AREA_FEATURE'

// Circle Search
export const SET_LOOKUP_LOCATION = 'MAPS/SET_LOOKUP_LOCATION'
export const SET_CIRCLE_SEARCH_LOCATION = 'MAPS/SET_CIRCLE_SEARCH_LOCATION'
export const SET_CIRCLE_SEARCH_RESULT = 'MAPS/SET_CIRCLE_SEARCH_RESULT'
export const SET_CIRCLE_HEATMAP = 'MAPS/SET_CIRCLE_HEATMAP'
export const CLEAR_CIRCLE_HEATMAP = 'MAPS/CLEAR_CIRCLE_HEATMAP'
export const SET_CIRCLE_EXCLUDE_LIST = 'MAPS/SET_CIRCLE_EXCLUDE_LIST'

// Zip Search
export const SET_ZIP_RANGE = 'MAPS/SET_ZIP_RANGE'
export const SET_SEARCH_RESULT = 'MAPS/SET_SEARCH_RESULT'
export const SET_ZIP_EXCLUDE_LIST = 'MAPS/SET_ZIP_EXCLUDE_LIST'
export const REMOVE_ZIP_RANGE = 'MAPS/REMOVE_ZIP_RANGE'

// Common heatmap (TODO: Use same source for heatmap independent of search method)
export const SET_HEATMAP = 'MAPS/SET_HEATMAP'
export const CLEAR_HEATMAP = 'MAPS/CLEAR_HEATMAP'

// BRF Search
export const ADD_BRF = 'MAPS/ADD_BRF'
export const REMOVE_BRF = 'MAPS/REMOVE_BRF'
export const SET_BRF_HEATMAP = 'MAPS/SET_BRF_HEATMAP'
export const SET_BRF_EXCLUDE_LIST = 'MAPS/SET_BRF_EXCLUDE_LIST'
export const SET_SAVED_BRFS = 'MAPS/SET_SAVED_BRFS'