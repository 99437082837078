<template>
  <ViewContainer>
    <SelectWorkflow />
  </ViewContainer>
</template>
<style lang="scss" scoped>
  @import './Start.scss';
</style>
<script>
import ViewContainer from '../components/ViewContainer.vue'
import SelectWorkflow from '../components/SelectWorkflow.vue'

export default {
	name: 'Start',
	components: { SelectWorkflow, ViewContainer },
}
</script>
