<template>
  <v-dialog
    v-model="dialog"
    class="modal"
    :transition="false"
    max-width="600"
		@click:outside="close"
		persistent
  >
    <template>
      <v-card>
        <v-toolbar class="toolbar" color="primary" dark>
          <div class="header text-h6">
            <slot name="title">slot: title</slot>
            <v-btn text @click="close"
              ><v-icon>fas fa-times</v-icon></v-btn
            >
          </div>
        </v-toolbar>
        <v-card-text>
          <div class="pt-4 keep-formatting">
            <slot name="default">slot: default</slot>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
	name: 'Modal',
	props: ['open'],
	data: () => ({
		dialog: false,
	}),
	methods: {
		close() {
			this.$emit('close')
		},
	},
	updated() {
		this.dialog = this.open
	},
	mounted() {
		this.dialog = this.open
	},
}
</script>

<style lang="scss" scoped>
@import "./Modal.scss";
</style>