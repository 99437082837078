import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@fortawesome/fontawesome-pro/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#2f96b4',
				table: '#f9f9f9',
			},
		},
	},
	icons: {
		iconfont: 'fa',
	},
})
