import { DEFAULT_RESULT_LIMIT } from './constants'

export default {
	getApiToken(state) {
		return state.session.apiToken
	},
	getWorkflow(state) {
		return state.workflow
	},
	getFileuploadAddressCount(state) {
		return state.fileUpload.addresses.length
	},
	getIncompletePolys(state) {
		// Used to return number of polygons with amount = 0
		// This can be used to block the "continue button" if we are in polygon-search
		// (ordering with an incomplete polygon breaks -- that could be patched instead of this check)
		if (!state.maps.polygons) {
			return false
		}
		const incompletePolys = state.maps.polygons.filter(p => p.addressCount === 0)
		if (incompletePolys.length > 0) {
			return true
		}
		return false
	},
	getAddressCount(state, getters) {
		let addressCount = 0
		switch (state.workflow.join('/')) {
		case 'map/circle':
			addressCount = getters.getCircleAddressCount
			break
		case 'map/areas':
			addressCount = getters.getAreaAddressCount
			break
		case 'map/areacodes':
			addressCount = getters.getSearchResultAddressCount
			break
		case 'map/brf':
			addressCount = getters.getSearchResultAddressCount
			break
		case 'fileupload':
			addressCount = getters.getFileuploadAddressCount
			break
		default:
			break
		}
		if (addressCount === undefined || isNaN(addressCount)) {
			addressCount = 0
		}
		return addressCount
	},
	getMaxAmountForAdr(state) {
		// As of now we only have one type (adr). If we at some time need to expand,
		// add new products (e.g. maxAmounts for "just phone") and either rewrite this function or
		// create new functions for each product
		let maxAmount = 0
		const maxAmountObj = state.session.maxAmounts.find((entry) => [entry.product === 'adr'])
		if (maxAmountObj) {
			maxAmount = +maxAmountObj.maxAmount
		}
		return maxAmount
	},
	getMinAmountForAdr() {
		// Using the same amount for phone numbers
		// TODO: Should this just be hardcoded to 10 or should it be in a DB / differ in verticals? See "getMaxAmountForAdr"
		const minAmount = 10
		return minAmount
	},
	getPhoneCount(state, getters) {
		let phoneCount = 0
		switch (state.workflow.join('/')) {
		case 'map/circle':
			phoneCount = getters.getCirclePhoneCount
			break
		case 'map/areas':
			phoneCount = getters.getAreaPhoneCount
			break
		case 'map/areacodes':
			// TODO: why was the following line not here before? It was only an empty statement. Was it not needed because there were no break here before?
			phoneCount = getters.getSearchResultPhoneCount
			break
		case 'map/brf':
			phoneCount = getters.getSearchResultPhoneCount
			break
		default:
			break
		}
		if (phoneCount === undefined || isNaN(phoneCount)) {
			phoneCount = 0
		}
		return phoneCount
	},
	getPaperWeightOptions(state) {
		return state.session.paperWeightOptions
	},
	getTargetAudienceParams(state) {
		const {
			age: { value: age },
			buildingTypes: { values: buildingTypes },
			gender: { value: gender },
			includePhoneNumbers: { value: includePhoneNumbers },
			resultLimit: { value: resultLimit },
			roomCount: { value: roomCount },
			livingArea: { value: livingArea },
			buildingYear: { value: buildingYear },
			kitchenTypes: { value: kitchenTypes },
			phaseOfLife: { value: phaseOfLife },
			payingAbility: { value: payingAbility },
			purchasingPower: { value: purchasingPower },
			income: { value: income },
			education: { value: education },
		} = state.targetAudience

		const params = {
			includePhoneNumbers,
			resultLimit,
			buildingTypes: buildingTypes.map(val => +val),
			gender,
			ageIntervals: [{ min: age.min, max: age.max }],
		}

		if (roomCount.apply) {
			params.roomCount = { min: +roomCount.min, max: +roomCount.max }
		}
		if (livingArea.apply) {
			params.livingArea = { min: +livingArea.min, max: +livingArea.max }
		}
		if (buildingYear.apply) {
			params.buildingYear = { min: +buildingYear.min, max: +buildingYear.max }
		}
		if (kitchenTypes.apply) {
			params.kitchenTypes = kitchenTypes.values
		}
		if (phaseOfLife.apply) {
			params.phaseOfLife = phaseOfLife.values
		}
		if (payingAbility.apply) {
			params.payingAbility = payingAbility.values
		}
		if (purchasingPower.apply) {
			params.purchasingPower = purchasingPower.values
		}
		if (income.apply) {
			params.income = income.values
		}
		if (education.apply) {
			params.education = education.values
		}
		return params
	},
	getOrderData(state) {
		return state.orderData
	},
	getFinalBookingData(state, getters) {
		const data = {
			orderIdentification: `${getters.getOrderIdentification}`,
			addressCount: +getters.getAddressCount,
			orderData: getters.getOrderData,
			filters: getters.getTargetAudienceParams,
			excludeAddresses: getters.getAddressesToExclude,
			workflow: state.workflow,
			location: getters.getMapLocation,
		}

		switch (getters.getWorkflow.join('/')) {
		case ('map/areas'):
			return {
				areasearch: getters.getAreasToSearch,
				...data,
				filters: {
					...data.filters,
					resultLimit: DEFAULT_RESULT_LIMIT,
				},
			}
		case ('map/circle'):
			return {
				...data,
				circleSearch: getters.getCircleSearchParams,
			}
		case ('map/areacodes'): {
			const { zipRanges } = getters.getZipSearchParams
			return {
				...data,
				zipSearch: {
					zipType: 'Range',
					zip: zipRanges.map(range => range.join('-')).join(','),
				},
				filters: {
					...data.filters,
					resultLimit: DEFAULT_RESULT_LIMIT,
				},
			}
		}
		case ('map/brf'): {
			const { selected } = getters.getBrfSearchParams
			return {
				...data,
				brfSearch: selected.map(({ id }) => +id),
				filters: {
					...data.filters,
					resultLimit: DEFAULT_RESULT_LIMIT,
				},
			}
		}
		case 'fileupload': {
			return {
				...data,
				format: state.fileUpload.format,
				originalFileName: state.fileUpload.originalFileName,
				addresses: state.fileUpload.addresses.map((line) => line.reduce((ack, col) => ({
					...ack,
					[col.id]: col.value,
				}), {})),
			}
		}
		default:
			return data
		}
	},
	getFinalFormData(state, getters) {
		// TODO: make sure distributor is available
		// TODO: Investigate how tblNEIGHBOURAD_SETUP_AREA_id is used
		const { deadlineDate, paperWeight, refCopy, reference } = getters.getOrderData
		const { buildingTypes } = getters.getTargetAudienceParams
		const { address, brokerName, postcode } = reference
		const areas = getters.getAreasToSearch
		return {
			deadlineDate,
			brokerName,
			brokerAttention: brokerName,
			brokerAddress: address,
			brokerPostCode: postcode,
			paperWeight,
			doNotSendCopiesToDepartment: !refCopy,
			useType: 'silvermap',
			amount: getters.getAddressCount,
			streetAddress: null,
			postCode: null,
			housingTypes: buildingTypes,
			polygon: areas,
			numberOfHits: getters.getAddressCount,
			isHTMLmap: true,
			tblNEIGHBOURAD_SETUP_AREA_id: null,
			isPAR: null,
			isSDR: null,
		}
	},
	getOrderIdentification(state) {
		const { objectId, configData: { searchConfig: { orderIdentification } } } = state.session
		return (orderIdentification || objectId)
	},
}
