import { getAxiosClient } from '../httpClient'

const serviceURL = process.env.VUE_APP_MAPAPI_URL

const cache = {}
let request = null

const focusAreaArray = (focusAreas) => Object.keys(focusAreas).map((key) => focusAreas[key])

export async function fetchFocusAreas({ apiToken }) {
	if (cache.focusAreas) {
		return Promise.resolve(focusAreaArray(cache.focusAreas))
	}

	if (request) {
		return request
	}

	request = getAxiosClient({ apiToken }).get(`${serviceURL}/focusareas`)

	const { data } = await request

	cache.focusAreas = data.reduce((ack, area) => {
		Object.assign(ack, { [area.id]: { areaId: area.id, ...area } })
		return ack
	}, {})

	return focusAreaArray(cache.focusAreas)
}

export async function fetchFocusArea({ apiToken, areaId }) {
	await fetchFocusAreas({ apiToken })
	return cache.focusAreas[areaId]
}

export async function addTemporaryFocusArea({ apiToken }, { brokerId, areaName, buildingTypes, coordinates }) {
	await fetchFocusAreas({ apiToken })
	cache.focusAreas[-1] = {
		areaId: -1,
		brokerId,
		areaName,
		buildingTypes,
		coordinates: coordinates.map(({ lat, lon, lng }) => ({ lat, lon: lon || lng })),
	}
	return -1
}

export async function saveFocusArea({ apiToken }, { id, brokerId, areaName, buildingTypes, coordinates }) {
	const { areaId, ...current } = (id && cache.focusAreas[id]) || {}
	const postId = id && id > 0 ? id : ''
	const payload = {
		...current,
		areaName: areaName || current.areaName || areaId || '-',
		brokerId,
		buildingTypes: buildingTypes.join(','),
		coordinates,
	}
	const { data } = await getAxiosClient({ apiToken }).post(`${serviceURL}/focusareas/${postId}`, payload)
	const { id: dbid, ...dbprops } = data
	const updatedFocusArea = { areaId: dbid, ...dbprops }
	cache.focusAreas[dbid] = updatedFocusArea

	// Tempobject is added for some reason ... this removes it so reload of focusareas works
	delete cache.focusAreas[-1]

	return updatedFocusArea
}

export async function deleteFocusArea({ apiToken }, { id }) {
	const { data } = await getAxiosClient({ apiToken }).delete(`${serviceURL}/focusareas/${id}`)
	delete cache.focusAreas[id]
	return data
}