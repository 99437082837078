<template>
  <div class="start">
    <div class="cards">
      <v-card :class="[ entry.route.indexOf(workflow) > -1 ? 'selectedWorkflow' : '']" class="card" :key="entry.title" v-for="entry in workflows.filter(item => item.enabled)">
        <v-card-title>
          <v-icon>{{ entry.icon }}</v-icon>
          {{ $t(`SelectWorkflow.${entry.title}`) }}
        </v-card-title>
        <v-card-text>{{ $t(`SelectWorkflow.${entry.text}`) }}</v-card-text>
        <div class="cta">
            <v-btn color="primary" @click="setWorkflow($event, entry)">
              {{ $t("SelectWorkflow.Select") }}
              <v-icon small>fas fa-arrow-alt-circle-right</v-icon>
            </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>

import messageBus from '../services/messageBus/MessageBus'

export default {
	name: 'SelectWorkflow',
	computed: {
		workflow() {
			if (this.$store.getters.getWorkflow.length === 0) {
				return 'none'
			}
			return this.$store.getters.getWorkflow.join('/')
		},
		workflows() {
			return this.$store.state.workflows
		},
	},
	methods: {
		setWorkflow(e, entry) {
			// To disable high level click event on app
			e.stopPropagation()

			if (entry.route === '/holidayhomes') {
				messageBus.$emit('DISABLEDISTRIBUTION', true)
			}
			this.$store.dispatch('setWorkflow', { workflow: entry.workflow })
			this.$router.push({ path: entry.route })
		},
	},
}
</script>
<style lang="scss" scoped>
@import "./SelectWorkflow.scss";
</style>