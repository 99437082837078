import getPolygonCoordinates from '../../utils/polygons'

export default {
	getMapsConfig: (state) => state.maps.config,
	getNextPolygonColor: (state) => {
		const { polygons, colors } = state.maps
		// Find first available color. If > max colors, use first
		let index = 0
		const usedColors = polygons.map(p => p.colorIndex)
		for (let i = 0; i < colors.length; i += 1) {
			if (!usedColors.includes(i)) {
				index = i
				break
			}
		}
		const color = colors[index]
		return { color, index }
	},
	getActivePolygon: (state) => {
		const { polygons } = state.maps
		return polygons.find((p) => !!p.active)
	},
	getAreasToSearch: (state) => state.maps.polygons.reduce((areas, polygon) => {
		const coordinates = getPolygonCoordinates(polygon)
		return [...areas, { name: polygon.name, coordinates }]
	}, []),
	getCircleSearchParams: (state) => state.maps.circleSearch.address,
	getZipSearchParams: (state) => state.maps.zipSearch,
	getBrfSearchParams: (state) => state.maps.brfSearch,
	findPolygon: (state) => (id) => state.maps.polygons.find((p) => p.id === id),
	findFocusArea: (state) => (id) => state.maps.focusAreas.areas.find((a) => a.id === id),
	findColor: (state) => (index) => state.maps.colors[index],
	getCircleAddressCount: (state) => state.maps.circleSearch.count - state.maps.circleSearch.excludeList.reduce((ack, { count }) => ack + count, 0),
	getAreaAddressCount: (state) => state.maps.polygons.reduce((total, polygon) => total + polygon.addressCount - polygon.excludeList.reduce((ack, { count }) => ack + count, 0), 0),
	getSearchResultAddressCount: (state) => state.maps.searchResult.count - state.maps.searchResult.excludeList.reduce((ack, { count }) => ack + count, 0),
	getSearchResultPhoneCount: (state) => state.maps.searchResult.phoneCount - state.maps.searchResult.excludeList.reduce((ack, { tel }) => ack + tel, 0),
	getAreaPhoneCount: (state) => state.maps.polygons.reduce((total, polygon) => total + polygon.phoneCount - polygon.excludeList.reduce((ack, { tel }) => ack + tel, 0), 0),
	getCirclePhoneCount: (state) => state.maps.circleSearch.phoneCount - state.maps.circleSearch.excludeList.reduce((ack, { tel }) => ack + tel, 0),
	getMapLocation: (state) => state.maps.location,
	getAddresses: (state) => {
		switch (state.workflow.join('/')) {
		case 'map/areas':
			return state.maps.polygons.reduce((ack, polygon) => [...ack, ...(polygon.addresses || [])], [])
		case 'map/circle':
			return state.maps.circleSearch.addresses
		case 'map/areacodes':
		case 'map/brf':
			return state.maps.searchResult.addresses
		case 'fileupload':
			return state.fileUpload.addresses.map(line => line.reduce((record, field) => {
				const { id, value, citymail } = field
				return {
					...record,
					count: 1,
					[id]: value,
					...(typeof citymail !== 'undefined' ? { citymail } : {}),
				}
			}, {}))
		default:
			return []
		}
	},
	getAddressesToExclude: (state) => {
		switch (state.workflow.join('/')) {
		case 'map/areas':
			return state.maps.polygons.reduce((ack, polygon) => [...ack, ...polygon.excludeList], [])
		case 'map/circle':
			return state.maps.circleSearch.excludeList
		case 'map/areacodes':
		case 'map/brf':
			return state.maps.searchResult.excludeList
		case 'fileupload':
		default:
			return []
		}
	},
	getCitymailCount: (state) => {
		switch (state.workflow.join('/')) {
		case 'map/areas': {
			return state.maps.polygons.reduce((ack, polygon) => {
				const { cityMailCount } = polygon
				return ack + cityMailCount
			}, 0)
		}
		case 'map/circle':
			return state.maps.circleSearch.cityMailCount
		case 'map/areacodes':
		case 'map/brf':
			return state.maps.searchResult.cityMailCount
		default:
			return 0
		}
	},
}
