/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import * as constants from './constants'
import * as variants from '../constants/variants'

export default {
	[constants.SET_BUSY](state, { busy, spinnerSize }) {
		state.busy = busy
		state.spinnerSize = spinnerSize
	},
	[constants.SET_BOOKING](state, { isBooking }) {
		state.isBooking = isBooking
	},
	[constants.INIT_SESSION](state, session) {
		state.session = {
			...session,
			configData: {
				...state.session.configData,
				...session?.configData,
				searchConfig: {
					...state.session.configData.searchConfig,
					...session?.configData?.searchConfig,
				},
			},
		}
	},
	[constants.SET_WORKFLOW](state, { workflow, workflowConfig }) {
		// Note, workflowConfig was introduced to override hardcoded values (map/circle)
		state.workflow = workflow
		if (workflow.join('/') === 'map/circle') {
			if (workflowConfig) {
				state.targetAudience.resultLimit.value = workflowConfig.resultLimit
			} else {
				state.targetAudience.resultLimit.value = 500
			}
		}

		// Clear, before setting once
		state.cameraIsPositioned = null
	},
	[constants.SET_ALERT](state, { i18n = {}, raw = {} }) {
		state.alert = {
			...state.alert,
			i18n: { ...i18n },
			raw: { ...raw },
			display: true,
		}
	},
	[constants.UNSET_ALERT](state) {
		state.alert = {
			...state.alert,
			i18n: {},
			raw: {},
			display: false,
		}
	},
	[constants.SAVE_TARGET_AUDIENCE_SETTINGS](
		state,
		{
			age,
			gender,
			buildingTypes,
			includePhoneNumbers,
			roomCount,
			livingArea,
			kitchenTypes,
			buildingYear,
			phaseOfLife,
			payingAbility,
			purchasingPower,
			income,
			education,
			resultLimit,
		},
	) {
		if (age) {
			Object.entries(age).forEach(([key, value]) => {
				state.targetAudience.age.value[key] = value
			})
		}
		if (gender) {
			state.targetAudience.gender.value = gender
		}
		if (buildingTypes) {
			state.targetAudience.buildingTypes.values = buildingTypes
		}
		if (typeof includePhoneNumbers !== 'undefined') {
			state.targetAudience.includePhoneNumbers.value = includePhoneNumbers
		}

		if (roomCount) {
			const { apply, min, max } = roomCount
			state.targetAudience.roomCount.value = {
				...state.targetAudience.roomCount.value,
				apply: apply ?? state.targetAudience.roomCount.value.apply,
				max: max ?? state.targetAudience.roomCount.value.max,
				min: min ?? state.targetAudience.roomCount.value.min,
			}
		}

		if (livingArea) {
			const { apply, min, max } = livingArea
			state.targetAudience.livingArea.value = {
				...state.targetAudience.livingArea.value,
				apply: apply ?? state.targetAudience.livingArea.value.apply,
				max: max ?? state.targetAudience.livingArea.value.max,
				min: min ?? state.targetAudience.livingArea.value.min,
			}
		}

		if (buildingYear) {
			const { apply, min, max } = buildingYear
			state.targetAudience.buildingYear.value = {
				...state.targetAudience.buildingYear.value,
				apply: apply ?? state.targetAudience.buildingYear.value.apply,
				max: max ?? state.targetAudience.buildingYear.value.max,
				min: min ?? state.targetAudience.buildingYear.value.min,
			}
		}

		if (kitchenTypes) {
			const { apply, values } = kitchenTypes
			state.targetAudience.kitchenTypes.value = {
				...state.targetAudience.kitchenTypes.value,
				apply: apply ?? state.targetAudience.kitchenTypes.value.apply,
				values: apply === false ? [] : values ?? state.targetAudience.kitchenTypes.value.values,
			}
		}

		if (phaseOfLife) {
			const { apply, values } = phaseOfLife
			state.targetAudience.phaseOfLife.value = {
				...state.targetAudience.phaseOfLife.value,
				apply: apply ?? state.targetAudience.phaseOfLife.value.apply,
				values: apply === false ? [] : values ?? state.targetAudience.phaseOfLife.value.values,
			}
		}

		if (payingAbility) {
			const { apply, values } = payingAbility
			state.targetAudience.payingAbility.value = {
				...state.targetAudience.payingAbility.value,
				apply: apply ?? state.targetAudience.payingAbility.value.apply,
				values: apply === false ? [] : values ?? state.targetAudience.payingAbility.value.values,
			}
		}

		if (purchasingPower) {
			const { apply, values } = purchasingPower
			state.targetAudience.purchasingPower.value = {
				...state.targetAudience.purchasingPower.value,
				apply: apply ?? state.targetAudience.purchasingPower.value.apply,
				values: apply === false ? [] : values ?? state.targetAudience.purchasingPower.value.values,
			}
		}

		if (income) {
			const { apply, values } = income
			state.targetAudience.income.value = {
				...state.targetAudience.income.value,
				apply: apply ?? state.targetAudience.income.value.apply,
				values: apply === false ? [] : values ?? state.targetAudience.income.value.values,
			}
		}

		if (education) {
			const { apply, values } = education
			state.targetAudience.education.value = {
				...state.targetAudience.education.value,
				apply: apply ?? state.targetAudience.education.value.apply,
				values: apply === false ? [] : values ?? state.targetAudience.education.value.values,
			}
		}

		if (resultLimit) {
			state.targetAudience.resultLimit.value = +resultLimit
		}

		// TODO Every option should be in an array
		let numberOfExtraOptionsSelected = 0
		if (state.targetAudience.livingArea.value.apply === true) numberOfExtraOptionsSelected += 1
		if (state.targetAudience.roomCount.value.apply === true) numberOfExtraOptionsSelected += 1
		if (state.targetAudience.buildingYear.value.apply === true) numberOfExtraOptionsSelected += 1
		if (state.targetAudience.phaseOfLife.value.apply === true) numberOfExtraOptionsSelected += 1
		if (state.targetAudience.kitchenTypes.value.apply === true) numberOfExtraOptionsSelected += 1
		if (state.targetAudience.payingAbility.value.apply === true) numberOfExtraOptionsSelected += 1
		if (state.targetAudience.purchasingPower.value.apply === true) numberOfExtraOptionsSelected += 1
		if (state.targetAudience.income.value.apply === true) numberOfExtraOptionsSelected += 1
		if (state.targetAudience.education.value.apply === true) numberOfExtraOptionsSelected += 1
		state.numberOfExtraOptionsSelected = numberOfExtraOptionsSelected
	},
	[constants.UPDATE_ORDER_REFERENCE](state, { companyName, brokerName, address, postcode }) {
		if	(companyName) {
			state.orderData.reference.companyName = companyName
		}
		if	(brokerName) {
			state.orderData.reference.brokerName = brokerName
		}
		if	(address) {
			state.orderData.reference.address = address
		}
		if	(postcode) {
			state.orderData.reference.postcode = postcode
		}
	},
	[constants.SET_DEADLINE_DATE_PARAMS](state, { deadlineDate, distribution }) {
		state.orderData.deadlineDate = deadlineDate
		state.orderData.distribution = distribution
	},
	[constants.SET_REFCOPY](state, refCopy) {
		state.orderData.refCopy = refCopy
	},
	[constants.GIVE_CONSENT](state, params) {
		state.consent = { ...state.consent, ...params }
	},
	[constants.SET_FILEUPLOAD](state, { format, addresses, cityMailCount, originalFileName }) {
		state.fileUpload = { format, addresses, cityMailCount, originalFileName }
	},
	[constants.SET_VARIANT](state, variant) {
		if (variant === variants.TELEMARKETING) {
			const disable = ['holidayhomes', 'fileupload']
			const workflows = state.workflows.map(workflow => {
				if (disable.includes(workflow.workflow.join('/'))) {
					return { ...workflow, enabled: false }
				}
				return { ...workflow }
			})
			state.workflows = workflows
		}
	},
	[constants.RESET_ORDER_DATA](state) {
		state.orderData = {
			...state.orderData,
			paperWeight: 0,
			refCopy: false,
			deadlineDate: null,
			distribution: [],
			reference: {
				companyName: '',
				brokerName: '',
				address: '',
				postcode: '',
			},
		}
	},
}
