<template>
	<ul class="fill-flex">
		<slot name="default"></slot>
	</ul>
</template>

<script>
export default {
	name: 'UList',
}
</script>

<style lang="scss" scoped>
@import './Ul.scss';
</style>