<template>
	<div>
		<div class="slow-operation-alert" v-if="timeoutState === SLOW_SEARCH">
			<div class="pb-3">
				{{ $t('SlowSearchAlert') }}
			</div>
			<v-btn color="red" @click="cancelSearch">{{ $t('Cancel') }}</v-btn>
		</div>
		<div class="slow-operation-alert" v-if="timeoutState === SLOW_BOOKING">
			<div class="slow-booking-text">
				{{ $t('SlowBookingAlert') }}
			</div>
		</div>
	</div>
</template>

<script>
import messageBus from '../services/messageBus/MessageBus'
import { SLOW_BOOKING, SLOW_SEARCH } from '../constants/genericconstants'

export default {
	name: 'SlowOperationAlert',
	props: ['timeoutState'],
	data() {
		return {
			SLOW_SEARCH,
			SLOW_BOOKING,
		}
	},
	methods: {
		cancelSearch() {
			messageBus.$emit('CANCELSEARCH')
		},
	},
}
</script>

<style scoped lang="scss">
.slow-operation-alert {
	padding: 10px;
	text-align: center;
}

.slow-booking-text {
	color: white;
	background-color: rgba(0, 0, 0, 0.5)
}
</style>