<template>
	<v-app>
		<div class="app-wrapper" ref="appWrapper" id="appWrapper" @click="appIsClicked">
			<v-overlay v-if="busy">
				<Spinner />
				<SlowOperationAlert :timeoutState="timeoutState" />
			</v-overlay>
			<div v-if="initialized && !authorized">
				<p>We were not able to log you in at this time.</p>
			</div>
			<v-main v-if="authorized">
				<MainMenu />
				<router-view></router-view>
			</v-main>
			<modal :open="alert.display" @close="closeModal">
				<template slot="title">
					{{ alert.i18n.title ? $t(alert.i18n.title) : '' }}{{ alert.raw.title ? alert.raw.title : '' }}
				</template>
				<template slot="default">
					{{ alert.i18n.message ? $t(alert.i18n.message) : '' }}{{ alert.raw.message ? alert.raw.message : '' }}
				</template>
			</modal>
		</div>
	</v-app>
</template>
<script>
import queryString from 'query-string'
import MainMenu from './components/MainMenu.vue'
import Spinner from './components/Spinner.vue'
import { Modal } from './components/Common'
import SlowOperationAlert from './components/SlowOperatonAlert.vue'
import messageBus from './services/messageBus/MessageBus'
import { DEFAULT_LEFT_POSITION, SLOW_BOOKING, SLOW_SEARCH } from './constants/genericconstants'

export default {
	name: 'App',
	props: ['address'],
	components: {
		MainMenu,
		Spinner,
		Modal,
		SlowOperationAlert,
	},
	data: () => ({
		initialized: false,
		authorized: false,
		timer: null,
		timeoutState: '',
		slowSearchTimeout: 10000,
		slowBookingTimeout: 10000,
	}),
	computed: {
		busy() {
			return this.$store.state.busy
		},
		isBooking() {
			return this.$store.state.isBooking
		},
		apiToken() {
			return this.$store.state.session.apiToken
		},
		alert() {
			return {
				display: this.$store.state.alert.display,
				i18n: this.$store.state.alert.i18n,
				raw: this.$store.state.alert.raw,
			}
		},
	},
	watch: {
		busy() {
			this.handleTimeouts()
		},
	},
	methods: {
		handleTimeouts() {
			clearTimeout(this.timer)
			this.timeoutState = ''

			if (this.busy) {
				if (this.isBooking) {
					this.timer = setTimeout(() => {
						this.timeoutState = SLOW_BOOKING
					}, this.slowBookingTimeout)
				} else {
					this.timer = setTimeout(() => {
						this.timeoutState = SLOW_SEARCH
					}, this.slowSearchTimeout)
				}
			}
		},
		registerPolygonColors() {
			this.$store.state.maps.colors.forEach(({ r, g, b }, index) => {
				this.$refs.appWrapper.style.setProperty(`--polygon-color${index}`, `rgba(${r}, ${g}, ${b}, 1)`)
				this.$refs.appWrapper.style.setProperty(`--polygon-color-hint${index}`, `rgba(${r}, ${g}, ${b}, 0.25)`)
			})
		},
		closeModal() {
			this.$store.dispatch('unsetAlert')
		},
		appIsClicked() {
			messageBus.$emit('APPISCLICKED')
		},
		cancelSearch() {
			messageBus.$emit('CANCELSEARCH')
		},
		updateZoomLevel() {
			// Original zoom level works for screens larger than 1280x720
			let zoomLevel = 1
			const leftPosition = DEFAULT_LEFT_POSITION
			const windowWidth = window.innerWidth
			if (windowWidth >= 1100 && windowWidth <= 1280) {
				// This zoom level works for both 1280x720 and 1024x768. We do not take smaller screens into consideration
				zoomLevel = 0.8
			}
			if (windowWidth < 1100) {
				// This zoom level is meant for screens with an increased scale
				// Example in Windows: Settings -> Display -> Scale and layout -> "Change the size of text, apps and other items" -> 300%
				zoomLevel = 0.6
			}
			document.getElementById('appWrapper').style.zoom = zoomLevel
			if (document.getElementById('map-box')) {
				if (zoomLevel === 1) {
					document.getElementById('map-box').style.zoom = 1
					document.getElementById('map-box').style.left = `${leftPosition}px`
				} else {
					// if zoomlevel is 0.8 (or anything below 1)
					const newZoomLevel = 1 / zoomLevel
					document.getElementById('map-box').style.zoom = newZoomLevel
					const newLeft = leftPosition * zoomLevel
					document.getElementById('map-box').style.left = `${newLeft}px`
				}
			}
		},
	},
	created() {
		const query = queryString.parseUrl(document.URL).query
		const token = query.token || this.apiToken || window.sessionStorage.getItem('apiToken')
		if (token) {
			this.$store.dispatch('initSession', { apiToken: token })
				.then(success => {
					if (!success) {
						throw new Error('Failed to verify token')
					}
					this.authorized = !!success
					this.initialized = true

					// We need to override browser language with RES-language, by this time we should have this in session
					if (this.$store.state.session.language) {
						this.$i18n.locale = this.$store.state.session.language
					}
				})
				.catch((err) => {
					this.authorized = false
					this.initialized = true
					this.$store.dispatch('setAlert', {
						i18n: {
							title: 'Alerts.Invalid Token',
							message: 'Alerts.No valid session found',
						},
						raw: {
							message: `: ${err.message}`,
						},
					})
				})
		} else {
			this.authorized = false
		}
	},
	mounted() {
		this.registerPolygonColors()
		messageBus.$on('UPDATEZOOMLEVEL', this.updateZoomLevel)
		window.addEventListener('resize', this.updateZoomLevel)
		window.addEventListener('orientationchange', this.updateZoomLevel)
	},
	beforeDestroy() {
		messageBus.$off('UPDATEZOOMLEVEL', this.updateZoomLevel)
		window.removeEventListener('resize', this.updateZoomLevel)
		window.removeEventListener('orientationchange', this.updateZoomLevel)
		clearTimeout(this.timer)
	},
}
</script>
<style lang="scss">
@import "./App.scss";
</style>
