import { v4 as uuid } from 'uuid'
import { isMatch } from 'lodash'
import axios from 'axios'
import * as helpers from './helpers'
import { fetchFocusAreas, fetchFocusArea } from '../../services/focusAreas'
import { searchAreas, searchCircle, searchZip, searchBrfs } from '../../services/search'
import getPolygonCoordinates from '../../utils/polygons'
import searchMarkerImage from '../../assets/circleSearchMarker36px.png'
import { filterExcluded, getCitymailIneligible } from '../../utils/addresses'
import i18n from '../../i18n'
import messageBus from '../../services/messageBus/MessageBus'
import EmptyObjectError from '../../errorTypes/emptyObjectError'
import UndefinedError from '../../errorTypes/undefinedError'

import { DEFAULT_RESULT_LIMIT } from '../constants'

import {
	ADD_BRF,
	CLEAR_HEATMAP,
	CREATE_POLYGON,
	DELETE_POLYGON,
	MAPS_INIT_DONE,
	MAPS_INIT_IDLE,
	MAPS_INIT_RESET,
	MAPS_INIT_START,
	REMOVE_BRF,
	REMOVE_ZIP_RANGE,
	RESET_SEARCH_RESULTS,
	SET_BRF_EXCLUDE_LIST,
	SET_CIRCLE_EXCLUDE_LIST,
	SET_CIRCLE_HEATMAP,
	CLEAR_CIRCLE_HEATMAP,
	SET_CIRCLE_SEARCH_LOCATION,
	SET_CIRCLE_SEARCH_RESULT,
	SET_FOCUS_AREA_FEATURE,
	SET_HEATMAP,
	SET_LOOKUP_LOCATION,
	SET_MAP_CENTER,
	SET_MAP_LISTENERS,
	SET_PATH_DISABLED,
	SET_PATH_ENABLED,
	SET_POLYGON_ACTIVE,
	SET_POLYGON_INACTIVE,
	SET_POLYGON_PROPERTIES,
	SET_POLYGON_SUSPENDED,
	SET_SAVED_BRFS,
	SET_SEARCH_ADDRESS,
	SET_SEARCH_MARKER,
	SET_SEARCH_RESULT,
	SET_ZIP_EXCLUDE_LIST,
	SET_ZIP_RANGE,
	TOGGLE_FOCUS_AREAS,
	UNSET_MAP_LISTENERS,
	UNSET_SEARCH_MARKER,
	SET_BUILDINGTYPES,
} from './constants'

// TODO: Circle search marker
// import searchMarkerIcon from '../../assets/circleSearchMarker36px.png';

export default {
	setSearchAddress({ commit }, { address, error }) {
		commit(SET_SEARCH_ADDRESS, { address, error })
	},
	setMapCenter({ state, commit }, { lat, lng }) {
		const { map } = state.maps
		map.panTo(new window.google.maps.LatLng(lat, lng))
		commit(SET_MAP_CENTER, { lat, lng })
	},
	setMapZoomLevel({ state }, { zoomLevel }) {
		const { map } = state.maps
		map.setZoom(zoomLevel)
	},
	setCircleSearchLocation({ commit, dispatch }, { lat, lng }) {
		dispatch('clearCircleHeatmap')
		dispatch('resetSearchResults')
		commit(SET_CIRCLE_SEARCH_LOCATION, { lat, lng })
	},
	setSearchMarker({ state, dispatch, commit }, { lat, lng }) {
		dispatch('unsetSearchMarker')
		dispatch('setCircleSearchLocation', { lat, lng })
		const { map } = state.maps
		const marker = new window.google.maps.Marker({
			map,
			position: { lat, lng },
			icon: searchMarkerImage,
		})
		commit(SET_SEARCH_MARKER, { marker, lat, lng })
	},

	unsetSearchMarker({ state, commit }) {
		const marker = state.maps.markers.search
		if (marker) marker.setMap(null)
		commit(UNSET_SEARCH_MARKER)
	},
	lookupLocation({ commit }, { lat, lng }) {
		return new Promise((resolve, reject) => {
			const geocoder = new window.google.maps.Geocoder()
			geocoder.geocode({ location: { lat, lng } }, (results, status) => {
				if (status === 'OK' && helpers.isValidReverseGeocodeResult(results)) {
					commit(SET_LOOKUP_LOCATION, results)
					resolve()
				}
				reject(new Error('Geocode Error: lookupLocation'))
			})
		})
	},
	moveCameraToAddress({ state, dispatch }, { address, zoomLevel }) {
		const { map } = state.maps
		if (!map) throw new Error('Map unavailable')
		if (!address) throw new Error('Address missing')

		const Geocoder = new window.google.maps.Geocoder()
		return new Promise((resolve) => {
			Geocoder.geocode({ address }, async (results, status) => {
				try {
					if (status === 'OK') {
						const { lat, lng } = results[0].geometry.location
						const pos = { lat: lat(), lng: lng() }
						await dispatch('setMapCenter', pos)
						if (zoomLevel !== undefined) {
							await dispatch('setMapZoomLevel', { zoomLevel })
						}
						resolve()
					} else {
						throw new Error('Geocode: Invalid Response')
					}
				} catch (e) {
					dispatch('setSearchAddress', { error: 'Geocoding Failed' })
					resolve()
				}
			})
		})
	},
	geoCodeAdress({ state, dispatch }, { address }) {
		const { map } = state.maps
		if (!map) throw new Error('Map unavailable')
		if (!address) throw new Error('Address missing')

		const Geocoder = new window.google.maps.Geocoder()
		return new Promise((resolve) => {
			Geocoder.geocode({ address }, async (results, status) => {
				try {
					if (status === 'OK') {
						const { lat, lng } = results[0].geometry.location
						const pos = { lat: lat(), lng: lng() }
						await dispatch('setSearchAddress', { address })
						await dispatch('lookupLocation', pos)
						if (state.cameraIsPositioned) {
							// Camera is allready moved once
						} else {
							await dispatch('setMapCenter', pos)
						}
						await dispatch('setSearchMarker', pos)
						resolve()
					} else {
						throw new Error('Geocode: Invalid Response')
					}
				} catch (e) {
					dispatch('setSearchAddress', { error: 'Geocoding Failed' })
					resolve()
				}
			})
		})
	},

	resetMapState({ commit }) {
		commit(MAPS_INIT_RESET)
	},

	activateMap({
		state, commit, dispatch, getters,
	}) {
		const { map, polygons } = state.maps
		const listeners = [
			map.addListener('click', (evt) => dispatch('addPolygonVertex', evt)),
		]
		const areaTag = i18n.t('Area')
		if (polygons.length === 0) {
			if (state.session.configData.areas.length) {
				Promise.all(state.session.configData.areas.map(
					({ coordinates, name }) => dispatch('createPolygon', {
						name,
						coords: coordinates,
					}),
				)).then(([id, ...rest]) => {
					dispatch('setPolygonActive', { id })
					rest.forEach((pid) => dispatch('setPolygonInactive', { id: pid }))
				})
			} else if (state.session.configData.searchConfig.coordinates.length) {
				dispatch('createPolygon', {
					name: `${areaTag} ${polygons.length + 1}`,
					coords: state.session.configData.searchConfig.coordinates,
				}).then((id) => dispatch('setPolygonActive', { id }))
			} else {
				dispatch('createPolygon', {
					name: `${areaTag} ${polygons.length + 1}`,
				}).then((id) => dispatch('setPolygonActive', { id }))
			}
		} else {
			polygons.forEach(({ gPolygon }) => gPolygon.setMap(map))
			if (!getters.getActivePolygon) {
				dispatch('setPolygonActive', polygons[0])
			}
		}
		commit(SET_MAP_LISTENERS, { listeners })
	},

	deactivateMap({ state, commit, dispatch }) {
		const { listeners, polygons } = state.maps
		const { removeListener } = window.google.maps.event
		listeners.map((listener) => listener && removeListener(listener))
		polygons.forEach((polygon) => dispatch('setPolygonSuspended', polygon))
		dispatch('toggleFocusAreas', { active: false })
		commit(UNSET_MAP_LISTENERS)
	},

	registerMapsCallback({ state, commit, dispatch }, { mapNodeId }) {
		if (!window.mapsApiCallback) {
			window.mapsApiCallback = () => {
				const { address } = state.maps.location.addressSearch
				const mapNode = document.getElementById(mapNodeId)
				const map = new window.google.maps.Map(mapNode, {
					fullscreenControl: false,
					mapTypeId: 'satellite',
					center: {
						lat: state.maps.location.latLng.lat,
						lng: state.maps.location.latLng.lng,
					},
					zoom: 16,
					mapTypeControl: true,
					mapTypeControlOptions: {
						style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
						position: window.google.maps.ControlPosition.TOP_RIGHT,
					},
				})
				commit(MAPS_INIT_DONE, { mapNode, map })
				if (address) {
					dispatch('geoCodeAdress', { address })
				}
			}
		} else {
			window.mapsApiCallback()
		}
	},

	initMaps({ state, getters, commit }) {
		if (state.maps.status.loadState === MAPS_INIT_IDLE) {
			commit(MAPS_INIT_START)
			const { url, key, params } = getters.getMapsConfig
			const query = Object.entries({
				...params,
				key,
				callback: 'mapsApiCallback',
			})
				.map((entry) => entry.join('='))
				.join('&')
			const script = Object.assign(document.createElement('script'), {
				src: `${url}?${query}`,
				defer: true,
			})
			document.head.appendChild(script)
		}
	},

	setPolygonPathActive({ getters, commit, dispatch }, { id }) {
		const { gPolygon } = getters.findPolygon(id)
		const { addListener } = window.google.maps.event
		const { polygonPathIsCompliant } = helpers
		const path = gPolygon.getPath()
		const listeners = [
			addListener(
				path,
				'set_at',
				(pos, latLng) => {
					dispatch('polygonPathChanged', { id })
					return polygonPathIsCompliant(path) || setTimeout(() => path.setAt(pos, latLng), 200)
				},
			),
			addListener(
				path,
				'insert_at',
				(pos) => {
					dispatch('polygonPathChanged', { id })
					return polygonPathIsCompliant(path) || setTimeout(() => path.removeAt(pos), 200)
				},
			),
		]
		commit(SET_PATH_ENABLED, { id, listeners })
	},
	polygonPathChanged({ dispatch, commit }, { id }) {
		// this removes focus area info if something else is clicked, e.g. new coords on the map
		dispatch('unsetActiveFocusAreaFeature')

		commit(SET_POLYGON_PROPERTIES, { id, addressCount: 0, phoneCount: 0, hasRun: false })
	},
	setPolygonPathInactive({ getters, commit }, { id }) {
		const { listeners } = getters.findPolygon(id)
		const { removeListener } = window.google.maps.event
		listeners.path.forEach((l) => removeListener(l))
		commit(SET_PATH_DISABLED, { id })
	},

	setPolygonActive({ commit, getters, dispatch }, { id }) {
		const { id: previous } = getters.getActivePolygon || {}
		const polygon = getters.findPolygon(id)
		const { gPolygon, listeners } = polygon
		const { addListener, removeListener } = window.google.maps.event
		// cleanup
		if (previous && previous !== id) {
			dispatch('setPolygonInactive', { id: previous })
		}
		dispatch('unsetActiveFocusAreaFeature')

		listeners.inactive.forEach((listener) => removeListener(listener))

		// setup
		const activeListeners = [
			addListener(
				gPolygon,
				'rightclick',
				helpers.getVertexRemover({ gPolygon }),
			),
			addListener(gPolygon, 'dragstart', () => dispatch('setPolygonPathInactive', { id })),
			addListener(gPolygon, 'dragend', () => {
				dispatch('setPolygonPathActive', { id })
				dispatch('polygonPathChanged', { id })
			}),
			addListener(gPolygon, 'rightclick', () => dispatch('polygonPathChanged', { id })),
		]

		dispatch('setPolygonPathActive', { id })

		gPolygon.setEditable(true)
		gPolygon.setDraggable(true)

		const center = helpers.getPolygonCenter(polygon)
		if (center) {
			dispatch('setMapCenter', center)
		}
		// finalize
		commit(SET_POLYGON_ACTIVE, { id, listeners: { active: activeListeners } })
	},

	setPolygonInactive({ commit, getters, dispatch }, { id }) {
		const { removeListener } = window.google.maps.event
		const { gPolygon, listeners } = getters.findPolygon(id)
		// cleanup
		listeners.active.forEach((listener) => removeListener(listener))
		dispatch('setPolygonPathInactive', { id })
		// setup
		const inActiveListeners = [
			gPolygon.addListener('click', () => dispatch('setPolygonActive', { id })),
		]
		gPolygon.setEditable(false)
		gPolygon.setDraggable(false)
		// finalize
		commit(SET_POLYGON_INACTIVE, { id, listeners: inActiveListeners })
	},

	setPolygonSuspended({ dispatch, getters, commit }, { id }) {
		const { removeListener } = window.google.maps.event
		const { listeners } = getters.findPolygon(id)

		dispatch('setPolygonInactive', { id })
		listeners.inactive.forEach((l) => removeListener(l))
		commit(SET_POLYGON_SUSPENDED, { id })
	},
	async deletePolygon({ getters, commit, state, dispatch }, { id }) {
		const areaTag = i18n.t('Area')
		const { polygons } = state.maps
		const { gPolygon } = getters.findPolygon(id)
		gPolygon.setMap(null)
		commit(DELETE_POLYGON, { id })
		if (!polygons.length) {
			const newId = await dispatch('createPolygon', { name: `${areaTag} ${polygons.length + 1}` })
			dispatch('setPolygonActive', { id: newId })
		} else {
			const [{ id: polygonId }] = polygons
			dispatch('setPolygonActive', { id: polygonId })
		}
		dispatch('fixPolygonNames', state)
	},
	deleteActiveFocusAreaFeature({ commit, state }) {
		const feature = state.maps.map.data.getFeatureById(
			state.focusAreas.activeFeatureId,
		)
		state.maps.map.data.remove(feature)
		commit(SET_FOCUS_AREA_FEATURE, { id: null })
	},
	unsetActiveFocusAreaFeature({ state, commit }) {
		const feature = state.maps.map.data.getFeatureById(
			state.focusAreas.activeFeatureId,
		)
		state.maps.map.data.revertStyle(feature)
		commit(SET_FOCUS_AREA_FEATURE, { id: null })
	},
	async updateFocusAreaFeature({ state }, { id }) {
		const feature = state.maps.map.data.getFeatureById(id)
		const focusArea = await fetchFocusArea({ apiToken: state.session.apiToken, areaId: id })
		const { buildingTypes } = focusArea
		const areaType = helpers.buildingTypes2areaType(buildingTypes)
		feature.setProperty('areaType', areaType)
	},
	setActiveFocusAreaEditable({ state }, editable) {
		const feature = state.maps.map.data.getFeatureById(
			state.focusAreas.activeFeatureId,
		)
		state.maps.map.data.overrideStyle(feature, { editable, draggable: editable })
	},
	addFocusAreaToMap({ state }, focusArea) {
		const { areaId, buildingTypes, coordinates } = focusArea
		const areaType = helpers.buildingTypes2areaType(buildingTypes)
		const coords = coordinates.map(({ lat, lon, lng }) => ({
			lat,
			lng: lng || lon,
		}))

		state.maps.map.data.setStyle((feature) => {
			const { r, g, b } = state.focusAreas.colors[
				feature.getProperty('areaType')
			]
			const color = `rgb(${r},${g},${b})`
			return {
				strokeColor: color,
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: color,
				fillOpacity: 0.35,
			}
		})

		// Removing duplicate coordinates
		let newCoords = coords
		newCoords = newCoords.filter((v, i, a) => a.findIndex(t => (t.lng === v.lng && t.lat === v.lat)) === i)

		state.maps.map.data.add({
			geometry: new window.google.maps.Data.Polygon([newCoords]),
			id: areaId,
			properties: {
				areaType,
			},
		})
	},
	setFeatureActive({ dispatch, commit, state }, { id, active, edit = false }) {
		// Highlights the selected focus area
		const feature = state.maps.map.data.getFeatureById(id)
		if (active) {
			state.maps.map.data.revertStyle(feature)
			Promise.all([
				fetchFocusArea({ apiToken: state.session.apiToken, areaId: feature.getId() }),
				dispatch('unsetActiveFocusAreaFeature'),
			]).then(([area]) => {
				state.maps.map.data.overrideStyle(feature, {
					fillColor: 'rgb(64,100,144)',
					fillOpacity: 0.2,
				})
				commit(SET_FOCUS_AREA_FEATURE, { id, area, edit })
			})
		} else {
			dispatch('unsetActiveFocusAreaFeature')
		}
	},
	toggleFocusAreas({
		state, commit, dispatch,
	}) {
		if (!state.focusAreas.active) {
			const listeners = [
				state.maps.map.data.addListener('rightclick', (event) => {
					const compare = (c1, c2) => c1.lat() === c2.lat() && c1.lng() === c2.lng()
					let coordinateCount = 0
					let coords = []
					event.feature.getGeometry().forEachLatLng(latLng => {
						coordinateCount += 1
						coords = [
							...coords,
							...compare(latLng, event.latLng) ? [] : [latLng],
						]
					})

					if (coordinateCount > coords.length) {
						if (coords.length >= 3) {
							const polygon = new window.google.maps.Data.Polygon([coords])
							event.feature.setGeometry(polygon)
						} else {
							dispatch('setAlert', {
								i18n: {
									title: 'Alerts.Alert',
									message: 'Alerts.Delete not allowed (at least 3 points required)',
								},
							})
						}
					}
				}),
				state.maps.map.data.addListener('mouseover', (event) => {
					if (event.feature.getId() !== state.focusAreas.activeFeatureId) {
						state.maps.map.data.overrideStyle(event.feature, {
							fillOpacity: 0.6,
						})
					}
				}),
				state.maps.map.data.addListener('mouseout', (event) => {
					if (event.feature.getId() !== state.focusAreas.activeFeatureId) {
						state.maps.map.data.revertStyle(event.feature)
					}
				}),
				state.maps.map.data.addListener('click', (event) => {
					const id = event.feature.getId()
					if (id !== state.focusAreas.activeFeatureId) {
						dispatch('setFeatureActive', { id, active: true })
					} else {
						dispatch('unsetActiveFocusAreaFeature')
					}
				}),
			]
			return fetchFocusAreas({ apiToken: state.session.apiToken })
				.then((areas) => {
					areas.forEach((area) => dispatch('addFocusAreaToMap', area))
					commit(TOGGLE_FOCUS_AREAS, { active: true, listeners })
					return true
				})
		} else {
			const { removeListener } = window.google.maps.event
			state.maps.map.data.forEach((feature) => state.maps.map.data.remove(feature))
			state.focusAreas.listeners.map((listener) => removeListener(listener))
			commit(TOGGLE_FOCUS_AREAS, { active: false })
			return Promise.resolve(true)
		}
	},

	focusArea2Polygon({ dispatch }, polygon) {
		const name = polygon.areaName
		const coords = polygon.coordinates.map((c) => ({ lat: c.lat, lng: c.lon }))
		dispatch('toggleFocusAreas')
			.then(() => dispatch('createPolygon', { name, coords }))
			.then((id) => dispatch('setPolygonActive', { id }))
			.then(() => messageBus.$emit('HIGHLIGHTSEARCHBUTTON'))
	},

	updateBuildingTypes({ commit }, { buildingTypes }) {
		commit(SET_BUILDINGTYPES, buildingTypes)
	},

	fixPolygonNames({ state }) {
		// Reshuffle names, they should always keep same naming convention. This implementation could probably be better :)
		const areaTag = i18n.t('Area')
		if (!state.maps) {
			return
		}
		for (let i = 0; i < state.maps.polygons.length; i += 1) {
			state.maps.polygons[i].name = `${areaTag} ${i + 1}`
		}
	},

	createPolygon({ state, commit, getters, dispatch }, { name, coords = [] } = {}) {
		// Removing duplicate coordinates
		let newCoords = coords
		newCoords = newCoords.filter((v, i, a) => a.findIndex(t => (t.lng === v.lng && t.lat === v.lat)) === i)

		const areaTag = i18n.t('Area')
		const id = uuid()
		const {
			color: { r, g, b },
			index: colorIndex,
		} = getters.getNextPolygonColor
		const color = `rgb(${r},${g},${b})`
		const gPolygon = window.google?.maps && new window.google.maps.Polygon({
			path: newCoords,
			strokeColor: color,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: color,
			fillOpacity: 0.35,
			editable: true,
			map: state.maps.map,
		})
		const polygon = {
			id,
			name: name || `${areaTag} ${state.maps.polygonCount + 1}`,
			color,
			colorIndex,
			gPolygon,
			addressCount: 0,
			phoneCount: 0,
			excludeList: [],
			hasRun: false,
		}
		commit(CREATE_POLYGON, polygon)
		dispatch('fixPolygonNames', state)
		return id
	},

	addPolygonVertex({ getters }, { latLng }) {
		const activePolygon = getters.getActivePolygon
		if (activePolygon) {
			helpers.addVertex(activePolygon, latLng)
		}
	},

	setPolygonColor({ getters, commit }, { id, colorIndex }) {
		const { r, g, b } = getters.findColor(colorIndex)
		const { gPolygon } = getters.findPolygon(id)
		const color = `rgb(${r},${g},${b})`
		gPolygon.setOptions({
			fillColor: color,
			strokeColor: color,
		})
		commit(SET_POLYGON_PROPERTIES, { id, colorIndex, color })
	},
	setPolygonName({ commit }, { id, name }) {
		commit(SET_POLYGON_PROPERTIES, { id, name })
	},
	setPolygonAddressCount({ commit }, { id, addressCount }) {
		commit(SET_POLYGON_PROPERTIES, { id, addressCount })
	},
	setPolygonAddressesToExclude({ commit }, { id, excludeList }) {
		commit(SET_POLYGON_PROPERTIES, { id, excludeList })
	},
	async searchArea({ state, commit, getters, dispatch }, { id, cancelToken }) {
		const polygon = getters.findPolygon(id)
		try {
			const coordinates = getPolygonCoordinates(polygon)
			if	(coordinates.length < 3) {
				return { success: false }
			}
			const {
				includePhoneNumbers,
				...targetAudienceParams
			} = getters.getTargetAudienceParams

			const params = {
				areas: [{ coordinates }],
				soapAction: includePhoneNumbers ? 'AddressSelectionAreaTMDM' : 'AddressSelectionArea',
				orderIdentification: `${getters.getOrderIdentification}`,
				...targetAudienceParams,
				resultLimit: DEFAULT_RESULT_LIMIT,
			}

			const [result] = await searchAreas(state.session.apiToken, params, cancelToken)
			commit(SET_POLYGON_PROPERTIES, {
				id: polygon.id,
				addressCount: +result.Antal,
				phoneCount: +result.AntalTel,
				cityMailCount: +result.AntalCitymail,
				addresses: result.GatuAdresser,
				excludeList: [],
				hasRun: true,
			})
			return { success: true }
		} catch (error) {
			if (axios.isCancel(error)) {
				return { success: false, canceled: true }
			} else {
				dispatch('setAlert', {
					i18n: {
						title: 'Alerts.Alert',
						message: 'Alerts.Could not fetch addresses',
					},
				})
			}
			return { success: false }
		}
	},
	async searchCircle({ state, getters, dispatch, commit }, { resultLimit, cancelToken }) {
		try {
			const { includePhoneNumbers, ...targetAudienceParams } = getters.getTargetAudienceParams
			const circleSearchParams = getters.getCircleSearchParams
			const params = {
				soapAction: includePhoneNumbers ? 'AddressSelectionAdvancedTMDM' : 'AddressSelectionAdvanced',
				orderIdentification: `${getters.getOrderIdentification}`,
				...targetAudienceParams,
				...circleSearchParams,
				resultLimit,
			}

			const result = await searchCircle(state.session.apiToken, params, cancelToken)

			if (Object.keys(result).length === 0) {
				throw new EmptyObjectError()
			}
			commit(SET_CIRCLE_SEARCH_RESULT, result)
			return { success: true }
		} catch (error) {
			if (axios.isCancel(error)) {
				return { success: false, canceled: true }
			} else if (error instanceof EmptyObjectError) {
				dispatch('setAlert', {
					i18n: {
						title: 'CirclePane.EmptyObjectTitle',
						message: 'CirclePane.EmptyObjectMessage',
					},
				})
			} else {
				dispatch('setAlert', {
					i18n: {
						title: 'CirclePane.CouldNotFetchAddressesTitle',
						message: 'CirclePane.CouldNotFetchAddressesMessage',
					},
				})
			}
			return { success: false }
		}
	},
	async searchZipcodes({ state, getters, dispatch, commit }, cancelToken) {
		try {
			const { includePhoneNumbers, ...targetAudienceParams } = getters.getTargetAudienceParams
			const { zipRanges } = getters.getZipSearchParams

			const params = {
				soapAction: includePhoneNumbers ? 'AddressSelectionAdvancedTMDM' : 'AddressSelectionAdvanced',
				orderIdentification: `${getters.getOrderIdentification}`,
				...targetAudienceParams,
				zipType: 'Range',
				zip: zipRanges.map(range => range.join('-')).join(','),
				resultLimit: DEFAULT_RESULT_LIMIT,
			}
			const result = await searchZip(state.session.apiToken, params, cancelToken)
			commit(SET_SEARCH_RESULT, result)
			return { success: true }
		} catch (error) {
			if (axios.isCancel(error)) {
				return { success: false, canceled: true }
			} else {
				dispatch('setAlert', {
					i18n: {
						title: 'Alerts.Alert',
						message: 'Alerts.Could not fetch addresses',
					},
				})
				return { success: false }
			}
		}
	},
	addZipRange({ commit, getters }, zipRange) {
		const [newFrom, newTo] = zipRange.map(zip => +zip)
		const { zipRanges } = getters.getZipSearchParams
		for (let i = 0; i < zipRanges.length; i += 1) {
			const [from, to] = zipRanges[i]
			if ((newFrom >= from && newFrom <= to)
				|| (newTo >= from && newTo <= to)
				|| (newFrom < from && newTo > to)
				|| (from < newFrom && to > newTo)) {
				throw new Error('Overlapping range')
			}
		}
		commit(SET_ZIP_RANGE, [newFrom, newTo])
	},
	addBrf({ commit, state }, brf) {
		if (!state.maps.brfSearch.selected.some(existing => isMatch(existing, brf))) {
			commit(ADD_BRF, brf)
		}
	},
	removeBrf({ commit }, brf) {
		commit(REMOVE_BRF, brf)
	},
	setSavedBrfs({ commit }, brfs) {
		commit(SET_SAVED_BRFS, brfs)
	},
	async searchBrfAddresses({ commit, getters, state }, cancelToken) {
		const { selected } = getters.getBrfSearchParams
		const brfIds = selected.map(brf => brf.id)
		const { includePhoneNumbers } = getters.getTargetAudienceParams
		const { orderIdentification } = getters.getOrderIdentification
		const result = await searchBrfs(state.session.apiToken, {
			orderIdentification,
			soapAction: includePhoneNumbers ? 'AddressSelectionAdvancedTMDM' : 'AddressSelectionAdvanced',
			brfIds,
		}, cancelToken)
		commit(SET_SEARCH_RESULT, result)
	},
	clearCircleHeatmap({ state, commit }) {
		const { heatmap: previousHeatmap } = state.maps.circleSearch
		if (previousHeatmap) {
			previousHeatmap.setMap(null)
		}
		commit(CLEAR_CIRCLE_HEATMAP)
	},
	setCircleExcludeList({ commit }, excludeList) {
		commit(SET_CIRCLE_EXCLUDE_LIST, excludeList)
	},
	clearHeatmap({ state, commit }) {
		const { searchResult: { heatmap: previousHeatmap } } = state.maps
		if (previousHeatmap) {
			previousHeatmap.setMap(null)
		}
		commit(CLEAR_HEATMAP)
	},
	async createCircleHeatmap({ state, commit, dispatch }) {
		try {
			await dispatch('clearCircleHeatmap')
			await dispatch('clearHeatmap')
			const { map, circleSearch: { coordinates } } = state.maps

			if (typeof coordinates === 'object' && Object.keys(coordinates).length === 0) {
				throw new EmptyObjectError()
			}
			if (coordinates === undefined || coordinates === null) {
				throw new UndefinedError()
			}

			const coords = coordinates.filter(({ lat, lng }) => !!(lat && lng))

			if (coords.length) {
				const gcoords = coords.map(({ lat, lng }) => new window.google.maps.LatLng(lat, lng))
				const bounds = new window.google.maps.LatLngBounds()
				gcoords.forEach((coord) => bounds.extend(coord))
				map.fitBounds(bounds)
				const currentZoomLevel = map.getZoom()
				if (currentZoomLevel > 16) {
					map.setZoom(16)
				}
				const heatmap = new window.google.maps.visualization.HeatmapLayer({
					map,
					radius: 50,
					opacity: 0.9,
					maxIntensity: 30,
					scaleRadius: true,
					data: gcoords,
				})
				commit(SET_CIRCLE_HEATMAP, heatmap)
			}
		} catch (error) {
			if (error instanceof UndefinedError || error instanceof EmptyObjectError) {
				dispatch('setAlert', {
					i18n: {
						title: 'CirclePane.EmptyObjectTitle',
						message: 'CirclePane.EmptyObjectMessage',
					},
				})
			}
		}
	},
	async createHeatmap({ state, commit, dispatch }) {
		await dispatch('clearHeatmap')
		await dispatch('clearCircleHeatmap')
		const { map, searchResult: { coordinates } } = state.maps
		const coords = coordinates.filter(({ lat, lng }) => !!(lat && lng))
		if (coords.length) {
			const gcoords = coords.map(({ lat, lng }) => new window.google.maps.LatLng(lat, lng))
			const bounds = new window.google.maps.LatLngBounds()
			gcoords.forEach((coord) => bounds.extend(coord))
			map.fitBounds(bounds)
			const currentZoomLevel = map.getZoom()
			if (currentZoomLevel > 16) {
				map.setZoom(16)
			}
			const heatmap = new window.google.maps.visualization.HeatmapLayer({
				map,
				radius: 50,
				opacity: 0.9,
				maxIntensity: 30,
				scaleRadius: true,
				data: gcoords,
			})
			commit(SET_HEATMAP, heatmap)
		}
	},
	setZipExcludeList({ commit }, excludeList) {
		commit(SET_ZIP_EXCLUDE_LIST, excludeList)
	},
	setBrfExcludeList({ commit }, excludeList) {
		commit(SET_BRF_EXCLUDE_LIST, excludeList)
	},
	async removeZipRange({ commit, dispatch }, { index }) {
		await dispatch('clearHeatmap')
		commit(REMOVE_ZIP_RANGE, { index })
	},
	dropCitymailIneligible({ state, dispatch }) {
		const { workflow } = state
		switch (workflow.join('/')) {
		case 'map/areas': {
			const { polygons } = state.maps
			polygons.forEach(({ id, addresses, excludeList }) => {
				const citymailIneligible = getCitymailIneligible(filterExcluded(addresses, excludeList))
				dispatch('setPolygonAddressesToExclude', {
					id,
					excludeList: [
						...citymailIneligible,
						...excludeList,
					] })
			})
		}
			break
		case 'map/circle': {
			const { addresses, excludeList } = state.maps.circleSearch
			const citymailIneligible = getCitymailIneligible(filterExcluded(addresses, excludeList))
			dispatch('setCircleExcludeList', [
				...excludeList,
				...citymailIneligible,
			])
		}
			break
		case 'map/areacodes':
		case 'map/brf': {
			const { addresses, excludeList } = state.maps.searchResult
			const citymailIneligible = getCitymailIneligible(filterExcluded(addresses, excludeList))
			dispatch('setZipExcludeList', [
				...excludeList,
				...citymailIneligible,
			])
		}
			break
		case 'fileupload': {
			const { addresses, ...rest } = state.fileUpload
			const onlyCitymail = addresses.filter(line => line.some(col => !!col.citymail))
			dispatch('setFileUpload', {
				...rest,
				addresses: onlyCitymail,
			})
		}
			break
		default:
			dispatch('setAlert', {
				i18n: {
					title: 'Alerts.Alert',
					message: 'Alerts.Invalid workflow option',
				},
			})
		}
	},
	resetSearchResults({ commit }) {
		commit(RESET_SEARCH_RESULTS)
	},
}