import {
	DEFAULT_RESULT_LIMIT,
} from './constants'

import * as variants from '../constants/variants'

import {
	BUILDING_TYPE_DEFAULT,
	BUILDING_TYPE_TENANCY,
	BUILDING_TYPE_CONDO,
	BUILDING_TYPE_HOUSE,
	BUILDING_TYPE_COMBO,
	BUILDING_TYPE_OTHER,
} from './maps/constants'

export default {
	busy: false,
	isBooking: false,
	spinnerSize: null,
	location: 'swe',
	workflow: [],
	consent: {
		phoneNumbers: false,
		extraVariables: false,
	},
	workflows: [
		{
			enabled: true,
			route: '/map/areas',
			workflow: ['map', 'areas'],
			icon: 'fas fa-map',
			title: 'Map Area Selection',
			text: 'Target recipients by marking up areas on a map',
		}, {
			enabled: true,
			route: '/map/circle',
			workflow: ['map', 'circle'],
			icon: 'fas fa-crosshairs',
			title: 'Map Circle Selection',
			text: 'Target recipients around a centerpoint on a map',
		}, {
			enabled: true,
			route: '/map/areacodes',
			workflow: ['map', 'areacodes'],
			icon: 'fas fa-mail-bulk',
			title: 'Area Codes',
			text: 'Target recipients through area codes',
		}, {
			enabled: true,
			route: '/map/brf',
			workflow: ['map', 'brf'],
			icon: 'fas fa-building',
			title: 'Housing Cooperative',
			text: 'Target housing cooperatives',
		}, {
			enabled: true,
			route: '/fileupload',
			workflow: ['fileupload'],
			icon: 'fas fa-file-upload',
			title: 'File Upload',
			text: 'Use your own address file',
		}, {
			enabled: true,
			route: '/holidayhomes',
			workflow: ['holidayhomes'],
			icon: 'fas fa-home',
			title: 'Holiday Homes',
			text: 'Target holiday home owners',
		},
	],
	orderData: {
		paperWeight: 0,
		refCopy: false,
		deadlineDate: null,
		distribution: [],
		reference: {
			companyName: '',
			brokerName: '',
			address: '',
			postcode: '',
		},
	},
	session: {
		apiToken: null,
		id: null,
		dateAdded: null,
		guid: null,
		objectId: null,
		chainId: null,
		customerId: null,
		userId: null,
		resAddress: null,
		resZipcode: null,
		resCity: null,
		focusAreasAdmin: false,
		focusAreasEnabled: false,
		variant: variants.DRDIREKT,
		configData: {
			areas: [],
			tblNEIGHBOURAD_HTMLMAP_DATA_id: null,
			amount: null,
			zipCodes: null,
			resultLimit: null,
			insertedDate: null,
			phoneCount: null,
			searchConfig: {
				gender: null,
				coordinates: [],
				buildingTypes: [],
				age: {
					min: null,
					max: null,
				},
				orderIdentification: null,
			},
		},
	},
	alert: {
		defaultTimeout: 5000,
		display: false,
		i18n: {
			title: 'Alerts.Alert',
			message: 'Alerts.Generic',
		},
		raw: {
			title: '',
			message: '',
		},
	},
	numberOfExtraOptionsSelected: 0,
	targetAudience: {
		resultLimit: {
			value: DEFAULT_RESULT_LIMIT,
		},
		age: {
			min: 18,
			max: 120,
			value: { min: 20, max: 100 },
		},
		gender: {
			options: ['Male', 'Female', 'Both'],
			value: 'Both',
		},
		buildingTypes: {
			options: [
				{ label: 'condominium', value: 3552 },
				{ label: 'house', value: 30 },
				{ label: 'tenancy', value: 512 },
				{ label: 'other', value: 1 },
			],
			values: [3552, 30, 512, 1],
		},
		kitchenTypes: {
			options: [
				{ label: 'Kitchen', value: 1 },
				{ label: 'Kitchenette', value: 2 },
				{ label: 'Cooker Cabinet', value: 3 },
				{ label: 'No Kitchen', value: 4 },
				{ label: 'Data Unavailable', value: 9 },
			],
			value: {
				apply: false,
				values: [],
			},
		},
		roomCount: {
			value: {
				apply: false,
				min: 1,
				max: 8,
			},
		},
		livingArea: {
			value: {
				apply: false,
				min: 10,
				max: 200,
			},
		},
		buildingYear: {
			value: {
				apply: false,
				min: 1900,
				max: new Date().getFullYear(),
			},
		},
		phaseOfLife: {
			options: [
				{ value: 1, label: 'Youth' },
				{ value: 2, label: 'Single' },
				{ value: 3, label: 'With older children' },
				{ value: 4, label: 'Adults, no children' },
				{ value: 5, label: 'With small children' },
				{ value: 6, label: 'Well established' },
				{ value: 7, label: 'Middle age' },
				{ value: 8, label: 'Seniorer' },
			],
			value: {
				apply: false,
				values: [],
			},
		},
		includePhoneNumbers: {
			value: false,
		},

		purchasingPower: {
			options: [
				// { label: 'No data', value: 0 },
				{ label: 'Very high', value: 1 },
				{ label: 'High', value: 2 },
				{ label: 'Medium high', value: 3 },
				{ label: 'Medium low', value: 4 },
				{ label: 'Low', value: 5 },
				{ label: 'Very low', value: 6 },
			],
			value: {
				apply: false,
				values: [],
			},
		},
		payingAbility: {
			options: [
				// { label: 'No data', value: 0 },
				{ label: 'High', value: 1 },
				{ label: 'Medium high', value: 2 },
				{ label: 'Medium low', value: 3 },
				{ label: 'low', value: 4 },
			],
			value: {
				apply: false,
				values: [],
			},
		},
		income: {
			options: [
				// { label: 'No data', value: 0 },
				{ label: 'High', value: 1 },
				{ label: 'Medium high', value: 2 },
				{ label: 'Medium low', value: 3 },
				{ label: 'low', value: 4 },
			],
			value: {
				apply: false,
				values: [],
			},
		},
		education: {
			options: [
				// { label: 'No data', value: 0 },
				{ label: 'Long Academic', value: 1 },
				{ label: 'Short Academic', value: 2 },
				{ label: 'High School', value: 3 },
				{ label: 'Other', value: 4 },
			],
			value: {
				apply: false,
				values: [],
			},
		},
	},
	focusAreas: {
		active: false,
		activeFeatureId: null,
		activeAreaItem: null,
		editActiveItem: false,
		loadState: null,
		error: null,
		colors: {
			0: { r: 0, g: 0, b: 0 },
			[BUILDING_TYPE_OTHER]: { r: 0, g: 0, b: 0 },
			[BUILDING_TYPE_DEFAULT]: { r: 0, g: 0, b: 0 },
			[BUILDING_TYPE_TENANCY]: { r: 64, g: 100, b: 144 },
			[BUILDING_TYPE_CONDO]: { r: 180, g: 31, b: 37 },
			[BUILDING_TYPE_HOUSE]: { r: 242, g: 205, b: 85 },
			[BUILDING_TYPE_COMBO]: { r: 68, g: 185, b: 96 },
		},
		listeners: [],
	},
	fileUpload: {
		originalFileName: null,
		format: null,
		addresses: [],
	},
	holidayHomes: {
		municipalities: [],
		instructions: '',
		includePhoneNumbers: false,
	},
}

// https://maps.googleapis.com/maps/api/js?key=AIzaSyARU3FTw9fNKpmv26PRulnH--h6LoJD6mA&sensor=false&libraries=visualization&language=sv
