<template>
	<div class='panel-header'>
		<slot></slot>
	</div>
</template>

<script>

export default {
	name: 'PanelHeader',
}
</script>

<style lang='scss' scoped>
	.panel-header {
		padding: 0 4px 0 4px;
		text-align: center;
		h1 {
			font-size: 18px;
			line-height: 3em;
		}
		h2 {
			font-size: 14px;
			line-height: 2em;
		}
		button {
			margin-right: 4px;
		}
	}
</style>