import { getAxiosClient } from '../httpClient'

const serviceURL = process.env.VUE_APP_MAPAPI_URL
const cache = { bookingDates: null, orderInfo: null }

export function fetchBookingDates({ apiToken, workflow }) {
	if (cache.bookingDates) {
		return cache.BookingDates
	}
	return getAxiosClient({ apiToken })
		.get(`${serviceURL}/booking/dates?workflow=${workflow}`)
		.then(({ data }) => data)
}

export function saveDistributors() {
	return Promise.reject(new Error('Not implemented yet'))
}

export function processBooking({ apiToken, params }) {
	return getAxiosClient({ apiToken })
		.post(`${serviceURL}/booking/process`, params)
}

export function processHolidayHomeOrder({ apiToken, params }) {
	return getAxiosClient({ apiToken })
		.post(`${serviceURL}/booking/holiday-homes`, params)
}

export function processTelemarketingOrder({ apiToken, bookingData }) {
	return getAxiosClient({ apiToken })
		.post(`${serviceURL}/booking/telemarketing`, bookingData)
}