<template>
  <div class="markdown">
    <div v-html="markdownTextContent" />
    <div ref="mdSlot" class="slot">
			<slot name="default">Markup</slot>
		</div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify'
import marked from 'marked'

export default {
	name: 'Markdown',
	props: ['markdown'],
	data: () => ({
		input: '',
		markdownTextContent: '',
	}),
	watch: {
		markdown() {
			this.render()
		},
	},
	methods: {
		getMarkdownInput() {
			return this.markdown || this.$refs.mdSlot.textContent || this.input
		},
		render() {
			this.input = this.getMarkdownInput()
			const md = marked(this.input)
			const sanitized = DOMPurify.sanitize(md)
			this.markdownTextContent = `<div class='markdown'>${sanitized}</div>`
		},
	},
	mounted() {
		this.render()
	},
	updated() {
		if (this.getMarkdownInput() !== this.input) {
			this.render()
		}
	},
}
</script>

<style lang="scss" scoped>
.slot {
  display: none;
}
* ::v-deep .markdown {
	* {
		font-size: 14px;
	}
  h1 {
		font-size: 24px;
		margin-bottom: 0.5em;
  }
}
</style>