<template>
	<div>
		<v-toolbar class="nav">
			<v-toolbar-items>
				<v-btn text @click="navigate('/')">
					<span :class="[currentRoute === '/' ? 'selected-item-text' : '']">
						<span>{{ $t('MainMenu.Selection') }}</span>
						<i class="fas fa-mail-bulk fa-lg"></i>
					</span>
					<div v-if="currentRoute === '/'" class="active-caret">
						<i class="fas fa-caret-up"></i>
					</div>
				</v-btn>

				<v-btn text :disabled="workflow.indexOf('map/') === -1" @click="navigate('/map')">
					<span :class="[currentRoute.indexOf('/map') > -1 ? 'selected-item-text' : '']">
						<span>{{ $t('MainMenu.Map') }}</span>
						<i class="fas fa-map fa-lg"></i>
					</span>
					<div v-if="currentRoute.indexOf('/map') > -1" class="active-caret">
						<i class="fas fa-caret-up"></i>
					</div>
				</v-btn>

				<v-btn text :disabled="disableDistribution" @click="navigate('/distribution')">
					<span :class="[currentRoute.indexOf('/distribution') > -1 ? 'selected-item-text' : '']">
						<span>{{ $t('MainMenu.Distribution') }}</span>
						<i class="fas fa-shipping-fast fa-lg"></i>
					</span>
					<div v-if="currentRoute.indexOf('/distribution') > -1" class="active-caret">
						<i class="fas fa-caret-up"></i>
					</div>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>

		<div>
			<span v-if="getEnvironmentName" class="environment-name">
				({{ getEnvironmentName }})
			</span>
			<v-btn depressed class="button-close-modal" @click="closeModal">
				<i class="far fa-times-circle" />
			</v-btn>
		</div>
	</div>
</template>
<script>

import messageBus from '../services/messageBus/MessageBus'
import * as variants from '../constants/variants'

export default {
	name: 'MainMenu',
	data: () => ({
		currentRoute: '/',
		disableDistribution: true,
	}),
	methods: {
		onDisableDistribution(disableDistribution) {
			this.disableDistribution = disableDistribution
		},
		navigate(path) {
			if (this.$route.fullPath !== path) {
				this.$router.push(path)
			}
		},
		closeModal() {
			window.top.closePopup()
		},
	},
	computed: {
		isTelemarketing() {
			return this.$store.state.session.variant === variants.TELEMARKETING
		},
		workflow() {
			return this.$store.getters.getWorkflow.join('/')
		},
		getEnvironmentName() {
			let environmentName
			const url = window.location.host.split('.')[0].toUpperCase()
			if (url.includes('-DEV')) {
				environmentName = 'D'
			} else if (url.includes('-STAGE')) {
				environmentName = 'S'
			} else if (url.includes('-QA')) {
				environmentName = 'QA'
			} else if (url.includes('LOCALHOST')) {
				environmentName = 'L'
			}
			return environmentName
		},
	},
	watch: {
		$route(to) {
			this.currentRoute = to.fullPath
		},
	},
	mounted() {
		messageBus.$on('DISABLEDISTRIBUTION', this.onDisableDistribution)

		// To set caret position if reloading a saved search
		if (this.$router.currentRoute.fullPath) {
			this.currentRoute = this.$router.currentRoute.fullPath
		}
	},
	beforeDestroy() {
		messageBus.$off('DISABLEDISTRIBUTION', this.onDisableDistribution)
	},
}
</script>

<style scoped lang="scss">
@import './MainMenu.scss';
</style>
