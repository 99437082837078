import { getAxiosClient } from '../httpClient'

const serviceURL = process.env.VUE_APP_MAPAPI_URL

export async function brfSearch({ apiToken, text }) {
	const { data } = await getAxiosClient({ apiToken }).get(`${serviceURL}/brf/search-brf`, { params: { text: encodeURIComponent(text) } })
	return data
}

export async function fetchAddresses({ apiToken, ...data }) {
	const { data: result } = await getAxiosClient({ apiToken }).post(`${serviceURL}/brf/search-addresses`, data)
	return result
}

export async function fetchSaved({ apiToken }) {
	const { data } = await getAxiosClient({ apiToken }).get(`${serviceURL}/brf/saved`)
	return data
}

export async function fetchBrfs({ apiToken, savedBrfId }) {
	const { data } = await getAxiosClient({ apiToken }).post(`${serviceURL}/brf/fetch`, { savedBrfId })
	return data
}

export async function saveBrfSearch({ apiToken, name, brfIds, brfIdsOrgNumber }) {
	const { data } = await getAxiosClient({ apiToken }).post(`${serviceURL}/brf/save`, { name, brfIds, brfIdsOrgNumber })
	return data
}

export async function deleteBrfSavedEntry({ apiToken, id }) {
	const { data } = await getAxiosClient({ apiToken }).delete(`${serviceURL}/brf/search/${id}`)
	return data
}