<template>
	<div class="spinner">
		<i :class="spinnerSize" class="fas fa-spinner fa-spin spinner-transparent"></i>
		<div class="spinner-border">
			{{ $t('Spinner.Updating') }}
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		defaultSpinnerSize: 'fa-5x',
	}),
	computed: {
		spinnerSize() {
			return this.$store.state.spinnerSize ? `fa-${this.$store.state.spinnerSize}` : this.defaultSpinnerSize
		},
	},
}
</script>

<style scoped lang="scss" src=".\Spinner.scss">
</style>