export const getMarkdown = async (location, mdname) => {
	switch (`${location}/${mdname}`) {
	case 'swe/object-types': {
		const { default: md } = await import('./swe.object-types.md')
		return md
	}
	case 'swe/age-groups': {
		const { default: md } = await import('./swe.age-groups.md')
		return md
	}
	case 'swe/building-type-condominium': {
		const { default: md } = await import('./swe.building-type-condominium.md')
		return md
	}
	case 'swe/building-type-house': {
		const { default: md } = await import('./swe.building-type-house.md')
		return md
	}
	case 'swe/building-type-other': {
		const { default: md } = await import('./swe.building-type-other.md')
		return md
	}
	case 'swe/building-type-tenancy': {
		const { default: md } = await import('./swe.building-type-tenancy.md')
		return md
	}
	case 'swe/extra-variabler': {
		const { default: md } = await import('./swe.extra-variabler.md')
		return md
	}
	case 'swe/telefonnummer': {
		const { default: md } = await import('./swe.telefonnummer.md')
		return md
	}
	case 'swe/telefonnummer-fb': {
		const { default: md } = await import('./swe.telefonnummer-fb.md')
		return md
	}
	case 'swe/telefonnummer-mh': {
		const { default: md } = await import('./swe.telefonnummer-mh.md')
		return md
	}
	case 'swe/telefonnummer-generic': {
		const { default: md } = await import('./swe.telefonnummer-generic.md')
		return md
	}
	case 'swe/room-count': {
		const { default: md } = await import('./swe.room-count.md')
		return md
	}
	case 'swe/building-year': {
		const { default: md } = await import('./swe.building-year.md')
		return md
	}
	case 'swe/living-area': {
		const { default: md } = await import('./swe.living-area.md')
		return md
	}
	case 'swe/phase-of-life': {
		const { default: md } = await import('./swe.phase-of-life.md')
		return md
	}
	case 'swe/purchasing-power': {
		const { default: md } = await import('./swe.purchasing-power.md')
		return md
	}
	case 'swe/income-level': {
		const { default: md } = await import('./swe.income-level.md')
		return md
	}
	case 'swe/education-level': {
		const { default: md } = await import('./swe.education-level.md')
		return md
	}
	case 'swe/address-redirection': {
		const { default: md } = await import('./swe.address-redirection.md')
		return md
	}
	default:
		throw new Error(`Missing markdown: ${location}.${mdname}.md`)
	}
}
