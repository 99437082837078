<template>
	<li>
		<slot name="default"></slot>
	</li>
</template>

<script>
export default {
	name: 'Li',
}
</script>

<style lang="scss" scoped>
@import './Li.scss';
</style>