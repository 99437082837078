// Fundamental
export const DEFAULT_RESULT_LIMIT = 30000
export const INIT_SESSION = 'API/INIT_SESSION'
export const SET_WORKFLOW = 'SET_WORKFLOW'
export const SET_VARIANT = 'SET_VARIANT'

// Misc
export const SET_BUSY = 'SET_BUSY'
export const SET_BOOKING = 'SET_BOOKING'
export const SET_ALERT = 'SET_ALERT'
export const UNSET_ALERT = 'UNSET_ALERT'
export const GIVE_CONSENT = 'GIVE_CONSENT'

// Save address selection settings
export const SAVE_TARGET_AUDIENCE_SETTINGS = 'SAVE_TARGET_AUDIENCE_SETTINGS'

// Update order post data
export const UPDATE_ORDER_REFERENCE = 'UPDATE_ORDER_REFERENCE'
export const SET_DEADLINE_DATE_PARAMS = 'SET_DEADLINE_DATE_PARAMS'
export const SET_REFCOPY = 'SET_REFCOPY'

export const SET_FILEUPLOAD = 'SET_FILEUPLOAD'
export const RESET_ORDER_DATA = 'RESET_ORDER_DATA'