import Vue from 'vue'
import Vuex from 'vuex'
import initialState from './initialState'
import actions from './actions'
import getters from './getters'
import mapState from './maps/initialState'
import mapActions from './maps/actions'
import mutations from './mutations'
import mapMutations from './maps/mutations'
import mapGetters from './maps/getters'

Vue.use(Vuex)
export default new Vuex.Store({
	strict: false,
	state: {
		...initialState,
		maps: { ...mapState },
	},
	mutations: {
		...mutations,
		...mapMutations,
	},
	getters: {
		...getters,
		...mapGetters,
	},
	actions: {
		...actions,
		...mapActions,
	},
})

/*
https://maps.googleapis.com/maps/api/js?key=AIzaSyARU3FTw9fNKpmv26PRulnH--h6LoJD6mA&sensor=false&libraries=visualization&language=sv
*/
