// Div
export const DEFAULT_LEFT_POSITION = 405

export const BUILDING_TYPE = {
	CONDOMINIUM: 'condominium',
	HOUSE: 'house',
	TENANCY: 'tenancy',
	OTHER: 'other',
}

export const SLOW_SEARCH = 'SLOW_SEARCH'
export const SLOW_BOOKING = 'SLOW_BOOKING'