/* eslint-disable no-param-reassign */
import { reverseGeocodeResultToParams } from './helpers'
import messageBus from '../../services/messageBus/MessageBus'

import {
	// MAPS_INIT_IDLE,
	ADD_BRF,
	CLEAR_CIRCLE_HEATMAP,
	CLEAR_HEATMAP,
	CREATE_POLYGON,
	DELETE_POLYGON,
	MAPS_INIT_DONE,
	MAPS_INIT_FAIL,
	MAPS_INIT_RESET,
	MAPS_INIT_START,
	REMOVE_BRF,
	REMOVE_ZIP_RANGE,
	RESET_SEARCH_RESULTS,
	SET_BRF_EXCLUDE_LIST,
	SET_CIRCLE_EXCLUDE_LIST,
	SET_CIRCLE_HEATMAP,
	SET_CIRCLE_SEARCH_LOCATION,
	SET_CIRCLE_SEARCH_RESULT,
	SET_FOCUS_AREA_FEATURE,
	SET_HEATMAP,
	SET_LOOKUP_LOCATION,
	SET_MAP_CENTER,
	SET_MAP_LISTENERS,
	SET_PATH_DISABLED,
	SET_PATH_ENABLED,
	SET_POLYGON_ACTIVE,
	SET_POLYGON_INACTIVE,
	SET_POLYGON_PROPERTIES,
	SET_POLYGON_SUSPENDED,
	SET_SAVED_BRFS,
	SET_SEARCH_ADDRESS,
	SET_SEARCH_MARKER,
	SET_SEARCH_RESULT,
	SET_ZIP_EXCLUDE_LIST,
	SET_ZIP_RANGE,
	TOGGLE_FOCUS_AREAS,
	UNSET_MAP_LISTENERS,
	UNSET_SEARCH_MARKER,
	SET_BUILDINGTYPES,
} from './constants'

export default {
	[MAPS_INIT_RESET](state) {
		state.maps.status.loadState = MAPS_INIT_RESET
		state.maps.mapNode = null
		state.maps.map = null
	},
	[MAPS_INIT_START](state) {
		state.maps.status.loadState = MAPS_INIT_START
	},
	[MAPS_INIT_DONE](state, { mapNode, map }) {
		state.maps.status.loadState = MAPS_INIT_DONE
		state.maps.mapNode = mapNode
		state.maps.map = map
	},
	[MAPS_INIT_FAIL](state) {
		state.maps.status.loadState = MAPS_INIT_FAIL
	},
	[SET_MAP_LISTENERS](state, { listeners }) {
		state.maps.listeners.push(...listeners)
	},
	[UNSET_MAP_LISTENERS](state) {
		state.maps.listeners.splice(0, state.maps.listeners.length)
	},
	[SET_SEARCH_ADDRESS](state, { address, error }) {
		if (typeof address !== 'undefined') state.maps.location.addressSearch.address = address
		if (typeof error !== 'undefined') state.maps.location.addressSearch.error = error
	},
	[SET_MAP_CENTER](state, { lat, lng }) {
		state.maps.location.latLng.lat = lat
		state.maps.location.latLng.lng = lng

		// Set once
		state.cameraIsPositioned = true
	},
	[SET_POLYGON_ACTIVE](state, { id, listeners }) {
		const polygon = state.maps.polygons.find((p) => p.id === id)
		const { active: activeListeners } = listeners
		polygon.listeners.active.push(...activeListeners)
		polygon.listeners.inactive.length = 0
		polygon.active = true
	},
	[SET_POLYGON_INACTIVE](state, { id, listeners }) {
		state.maps.polygons.forEach((polygon) => {
			if (polygon.id === id) {
				polygon.active = false
				polygon.listeners.inactive.push(...listeners)
				polygon.listeners.active.length = 0
				polygon.listeners.path.length = 0
			}
		})
	},
	[SET_POLYGON_SUSPENDED](state, { id }) {
		state.maps.polygons.forEach((polygon) => {
			if (polygon.id === id) {
				polygon.listeners.inactive.length = 0
			}
		})
	},
	[CREATE_POLYGON](state, {
		id, name, colorIndex, color, gPolygon, addressCount, phoneCount, excludeList,
	}) {
		state.maps.polygonCount += 1
		state.maps.polygons.push({
			id,
			name,
			active: false,
			gPolygon,
			color,
			colorIndex,
			addressCount,
			phoneCount,
			excludeList,
			listeners: {
				path: [],
				active: [],
				inactive: [],
			},
			scheduled: [],
			hasRun: false,
		})
	},
	[DELETE_POLYGON](state, { id }) {
		const index = state.maps.polygons.findIndex((polygon) => polygon.id === id)
		state.maps.polygons.splice(index, 1)
	},
	[SET_PATH_ENABLED](state, { id, listeners }) {
		const polygon = state.maps.polygons.find((p) => p.id === id)
		polygon.listeners.path.push(...listeners)
	},
	[SET_PATH_DISABLED](state, { id }) {
		const polygon = state.maps.polygons.find((p) => p.id === id)
		polygon.listeners.path.length = 0
	},
	[SET_POLYGON_PROPERTIES](state, { id, ...props }) {
		const isDefined = (prop) => typeof prop !== 'undefined'
		if (id) {
			const polygon = state.maps.polygons.find((p) => p.id === id)
			if (isDefined(props.name)) polygon.name = props.name
			if (isDefined(props.color)) polygon.color = props.color
			if (isDefined(props.colorIndex)) polygon.colorIndex = props.colorIndex
			if (isDefined(props.addressCount)) polygon.addressCount = props.addressCount
			if (isDefined(props.phoneCount)) polygon.phoneCount = props.phoneCount
			if (isDefined(props.cityMailCount)) polygon.cityMailCount = props.cityMailCount
			if (isDefined(props.addresses)) polygon.addresses = props.addresses
			if (isDefined(props.excludeList)) polygon.excludeList = props.excludeList
			state.maps.polygons = state.maps.polygons.slice()
			if (isDefined(props.hasRun)) polygon.hasRun = props.hasRun
		}
	},
	[TOGGLE_FOCUS_AREAS](state, { active, listeners }) {
		if (active) {
			state.focusAreas.active = true
			state.focusAreas.listeners.push(...listeners)
		} else {
			state.focusAreas.active = false
			state.focusAreas.editActiveItem = false
			state.focusAreas.activeFeatureId = null
			state.focusAreas.listeners.length = 0
		}
	},
	[SET_FOCUS_AREA_FEATURE](state, { id = null, area = null, edit = false }) {
		state.focusAreas.activeFeatureId = id
		state.focusAreas.activeAreaItem = area
		state.focusAreas.editActiveItem = edit
	},
	[SET_SEARCH_MARKER](state, { marker, lat, lng }) {
		state.maps.markers.search = marker
		state.maps.circleSearch.location = { lat, lng }
	},
	[UNSET_SEARCH_MARKER](state) {
		state.maps.markers.search = null
		state.maps.circleSearch.location = null
	},
	[SET_LOOKUP_LOCATION](state, reverseGeocodeResult) {
		// This creates coordinates out of the searchAddress, then updates the address-search-component
		const {
			zipType,
			streetNumberType,
			...geoCodeResult
		} = reverseGeocodeResultToParams(reverseGeocodeResult, state.maps.location.addressSearch.address)
		state.maps.location = {
			...state.maps.location,
			...geoCodeResult,
		}
		state.maps.circleSearch.address = {
			...state.maps.circleSearch.address,
			...geoCodeResult,
			zipType,
			streetNumberType,
		}
		state.maps.location.addressSearch.error = null

		const orgAddress = state.maps.location.addressSearch.address

		// Bullding new address, new version without ? and spaces
		let newAddress = `${geoCodeResult.streetName}`
		if (geoCodeResult.streetNumberFrom) {
			newAddress += ` ${geoCodeResult.streetNumberFrom}`
		}
		if (geoCodeResult.streetSuffix) {
			newAddress += `${geoCodeResult.streetSuffix}`
		}
		if (geoCodeResult.streetNumberTo && geoCodeResult.streetNumberTo !== geoCodeResult.streetNumberFrom) {
			newAddress += ` - ${geoCodeResult.streetNumberTo}`
		}
		newAddress += geoCodeResult.city ? `, ${geoCodeResult.city}` : ''

		// The following is just to check whether we should show the userHint with "address changed"
		let orgAddressEdited = orgAddress.toUpperCase().replace(/ /g, '').replace(/[0-9]/g, '')
		let checkArray = orgAddressEdited.split(',')
		if (checkArray.length > 2) {
			orgAddressEdited = `${checkArray[0]},${checkArray[2]}`
		}
		let returnAddressEdited = newAddress.toUpperCase().replace(/ /g, '').replace(/[0-9]/g, '')
		checkArray = returnAddressEdited.split(',')
		if (checkArray.length > 2) {
			returnAddressEdited = `${checkArray[0]},${checkArray[2]}`
		}
		if (orgAddressEdited !== returnAddressEdited) {
			messageBus.$emit('DISPLAYADDRESSCHANGED')
		}

		// Always setting newAddress
		state.maps.location.addressSearch.address = newAddress
	},
	[SET_CIRCLE_SEARCH_LOCATION](state, location) {
		state.maps.circleSearch.location = location
	},
	[SET_CIRCLE_SEARCH_RESULT](state, result) {
		state.maps = {
			...state.maps,
			circleSearch: {
				...state.maps.circleSearch,
				count: result.Antal,
				phoneCount: result.AntalTel,
				cityMailCount: result.AntalCitymail,
				addresses: result.GatuAdresser,
				excludeList: [],
				coordinates: result.Koordinater,
			},
		}
	},
	[SET_BUILDINGTYPES](state, buildingTypes) {
		state.targetAudience.buildingTypes.values = buildingTypes
	},
	[SET_CIRCLE_HEATMAP](state, heatmap) {
		state.maps.circleSearch.heatmap = heatmap
	},
	[CLEAR_CIRCLE_HEATMAP](state) {
		state.maps.circleSearch.heatmap = null
	},
	[SET_CIRCLE_EXCLUDE_LIST](state, excludeList) {
		state.maps.circleSearch.excludeList = excludeList
	},
	[SET_ZIP_RANGE](state, zipRange) {
		state.maps.zipSearch.zipRanges = [...state.maps.zipSearch.zipRanges, zipRange]
		state.maps.searchResult = {
			...state.maps.searchResult,
			addresses: [],
			excludeList: [],
			cityMailCount: 0,
			coordinates: [],
			count: 0,
			heatmap: null,
			phoneCount: 0,
		}
	},
	[SET_HEATMAP](state, heatmap) {
		state.maps.searchResult.heatmap = heatmap
	},
	[CLEAR_HEATMAP](state) {
		state.maps.searchResult.heatmap = null
	},
	[SET_ZIP_EXCLUDE_LIST](state, excludeList) {
		state.maps.searchResult.excludeList = excludeList
	},
	[REMOVE_ZIP_RANGE](state, { index }) {
		state.maps.zipSearch.zipRanges.splice(index, 1)
		state.maps.searchResult = {
			...state.maps.searchResult,
			addresses: [],
			excludeList: [],
			cityMailCount: 0,
			coordinates: [],
			count: 0,
			heatmap: null,
			phoneCount: 0,
		}
	},
	[RESET_SEARCH_RESULTS](state) {
		const emtpyResult = (isPolygon = false) => ({
			cityMailCount: 0,
			phoneCount: 0,
			addresses: [],
			excludeList: [],
			...(isPolygon ? { addressCount: 0, phoneCount: 0, hasRun: false } : { count: 0 }),
			...(isPolygon ? { } : { coordinates: [] }),
		})
		state.maps.searchResult = {
			...state.maps.searchResult,
			...emtpyResult(),
		}
		state.maps.circleSearch = {
			...state.maps.circleSearch,
			...emtpyResult(),
			heatmap: null,
		}
		state.maps.polygons = state.maps.polygons.map((polygon) => ({
			...polygon,
			...emtpyResult(true),
		}))
	},
	[ADD_BRF](state, brf) {
		const brfset = new Set(state.maps.brfSearch.selected)
		brfset.add(brf)
		this.state.maps.brfSearch.selected = Array.from(brfset)
	},
	[REMOVE_BRF](state, brf) {
		const brfset = new Set(state.maps.brfSearch.selected)
		brfset.delete(brf)
		this.state.maps.brfSearch.selected = Array.from(brfset)
	},
	[SET_SEARCH_RESULT](state, result) {
		state.maps.searchResult.count = +result.Antal
		state.maps.searchResult.phoneCount = result.AntalTel
		state.maps.searchResult.cityMailCount = +result.AntalCitymail
		state.maps.searchResult.addresses = result.GatuAdresser
		state.maps.searchResult.excludeList = []
		state.maps.searchResult.coordinates = result.Koordinater
	},
	[SET_BRF_EXCLUDE_LIST](state, excludeList) {
		state.maps.searchResult.excludeList = excludeList
	},
	[SET_SAVED_BRFS](state, brfs) {
		state.maps.brfSearch.saved = brfs
	},
}
