<template>
	<div class='view-container'>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'ViewContainer',
}
</script>

<style lang="scss" scoped>
	@import './ViewContainer.scss';
</style>>
