import { difference, isEmpty } from 'lodash'
import router from '../router'
import { fetchBrfs } from '../services/brf'

import {
	SET_BUSY,
	SET_BOOKING,
	SET_ALERT,
	UNSET_ALERT,
	SAVE_TARGET_AUDIENCE_SETTINGS,
	INIT_SESSION,
	SET_WORKFLOW,
	UPDATE_ORDER_REFERENCE,
	SET_DEADLINE_DATE_PARAMS,
	SET_REFCOPY,
	GIVE_CONSENT,
	SET_FILEUPLOAD,
	SET_VARIANT,
	RESET_ORDER_DATA,
} from './constants'

import * as variants from '../constants/variants'

import {
	processBooking,
	processHolidayHomeOrder,
	processTelemarketingOrder,
} from '../services/booking'

import getSession from '../services/api'

export default {
	setBusy({ commit }, { busy, spinnerSize }) {
		commit(SET_BUSY, { busy, spinnerSize })
	},
	setBooking({ commit }, { isBooking }) {
		commit(SET_BOOKING, { isBooking })
	},
	async initSession({ commit, dispatch }, { apiToken }) {
		try {
			if (/^[a-z0-9-]{5,}$/i.test(apiToken)) {
				const { configData, ...session } = await getSession({ apiToken })
				const { searchConfig = {} } = configData || {}
				const searchAddress = { address: 'Slottsbacken 1, 11130, Stockholm' }

				if (session.objectId) {
					window.sessionStorage.setItem('apiToken', apiToken)

					if (session.variant) {
						dispatch('setVariant', session.variant)
					}

					if (configData.searchConfig && configData.searchConfig.streetName) {
						// If we have a saved search, this has first order
						searchAddress.address = `${configData.searchConfig.streetName} ${configData.searchConfig.streetNumberFrom}, ${configData.searchConfig.zip}, ${configData.searchConfig.city}`
					} else if (session.fallbackSearchAddress) {
						// If fallbackSearchAddress is defined it means something is wrong with the other addresses, so this trumps the rest
						searchAddress.address = session.fallbackSearchAddress
					} else if (searchConfig.city && searchConfig.streetName) {
						const { streetNumberFrom, streetNumberTo, streetSuffix, streetName, city, zip } = searchConfig
						const streetNumber = streetNumberFrom === streetNumberTo
							? streetNumberFrom
							: `${streetNumberFrom} - ${streetNumberTo}${streetSuffix && ` ${streetSuffix}`}`

						searchAddress.address = `${streetName} ${streetNumber}, ${zip}, ${city}`
					} else if (session.variant === variants.TELEMARKETING && session.objectType === 4) {
						// objectType === 4 means standalone telemarketing phone number order
						// initial adress is then configured through the object comment field....
						searchAddress.address = session.comment
					} else if (session.resAddress) {
						const { resAddress, resZip, resCity } = session

						searchAddress.address = [resAddress, resCity, resZip].filter(x => !!x).join(', ')
					} else if (session.customerCity && session.customerAddress) {
						const { customerAddress, customerZipCode, customerCity } = session

						searchAddress.address = `${customerAddress}, ${customerZipCode}, ${customerCity}`
					}

					// If we get config with extra variables allready set -- we know consent has been given (at least once)
					let allreadyGotExtraVariableConsent = false

					// Min/Max
					if (searchConfig.roomCount) {
						searchConfig.roomCount = { ...searchConfig.roomCount, apply: true }
						allreadyGotExtraVariableConsent = true
					}
					if (searchConfig.livingArea) {
						searchConfig.livingArea = { ...searchConfig.livingArea, apply: true }
						allreadyGotExtraVariableConsent = true
					}
					if (searchConfig.buildingYear) {
						searchConfig.buildingYear = { ...searchConfig.buildingYear, apply: true }
						allreadyGotExtraVariableConsent = true
					}
					// Arrays
					if (searchConfig.phaseOfLife) {
						searchConfig.phaseOfLife = { values: searchConfig.phaseOfLife, apply: true }
						allreadyGotExtraVariableConsent = true
					}
					if (searchConfig.purchasingPower) {
						searchConfig.purchasingPower = { values: searchConfig.purchasingPower, apply: true }
						allreadyGotExtraVariableConsent = true
					}
					if (searchConfig.income) {
						searchConfig.income = { values: searchConfig.income, apply: true }
						allreadyGotExtraVariableConsent = true
					}
					if (searchConfig.education) {
						searchConfig.education = { values: searchConfig.education, apply: true }
						allreadyGotExtraVariableConsent = true
					}

					if (searchConfig) {
						dispatch('saveTargetAudienceSettings', {
							...searchConfig.age && { age: searchConfig.age },
							...searchConfig.gender && { gender: searchConfig.gender },
							...searchConfig.buildingTypes && { buildingTypes: searchConfig.buildingTypes },
							...searchConfig.includePhoneNumbers && { includePhoneNumbers: searchConfig.includePhoneNumbers },
							...searchConfig.roomCount && { roomCount: searchConfig.roomCount },
							...searchConfig.livingArea && { livingArea: searchConfig.livingArea },
							...searchConfig.buildingYear && { buildingYear: searchConfig.buildingYear },
							...searchConfig.phaseOfLife && { phaseOfLife: searchConfig.phaseOfLife },
							...searchConfig.purchasingPower && { purchasingPower: searchConfig.purchasingPower },
							...searchConfig.income && { income: searchConfig.income },
							...searchConfig.education && { education: searchConfig.education },
						})
					}

					if (allreadyGotExtraVariableConsent === true) {
						await dispatch('giveConsent', { extraVariables: true })
					}

					dispatch('setSearchAddress', searchAddress)

					// Only if we load a previously set polygon
					if (configData.areas) {
						const areas = configData.areas.map(({ coordinates, ...rest }) => {
							if (coordinates?.length > 1
							&& coordinates[0].lat === coordinates[coordinates.length - 1].lat
							&& coordinates[0].lng === coordinates[coordinates.length - 1].lng) {
								return {
									...rest,
									coordinates: coordinates.slice(0, coordinates.length - 1),
								}
							}
							return { ...rest, coordinates }
						})
						commit(INIT_SESSION, { ...session, configData: { ...configData, areas }, apiToken })
					} else {
						// New docs
						commit(INIT_SESSION, { ...session, configData, apiToken })
					}

					// Do we have a saved search?
					if (configData.selectedWorkflow) {
						// Defaults
						const workflowname = configData.selectedWorkflow
						const workflow = workflowname.split('/')
						const workflowRoute = `/${workflowname}`
						router.push(workflowRoute).catch(() => {})

						// Repopulate zipCodes?
						if (workflowname === 'map/areacodes') {
							if (configData.zipCodes) {
								const zipCodes = configData.zipCodes.split(',')
								zipCodes.forEach((zipCode) => {
									const zipCodesToUpdate = zipCode.split('-')
									dispatch('addZipRange', zipCodesToUpdate)
								})
							}
						}

						// Repopulate brfIds?
						// TODO Refactor, this is copy-paste from BRFSearchForm
						if (workflowname === 'map/brf') {
							if (configData.tblNEIGHBOURAD_BOSTADSRETT_ids) {
								const brfIds = configData.tblNEIGHBOURAD_BOSTADSRETT_ids.split(',').map(Number)
								const brfs = await fetchBrfs({ apiToken, brfIds })
								for (let i = 0; i <= brfs.length; i += 1) {
									if (brfs[i]) {
										const nameCap = brfs[i].name.toLowerCase().replace(/(?:^|\s)\S/g, firstLetter => firstLetter.toUpperCase()).replace(' I ', ' i ')
										brfs[i].name = nameCap
										if (brfs[i] && brfs[i].city) {
											brfs[i].name = `${brfs[i].name} (${brfs[i].city})`
										}
									}
								}
								brfs.forEach(brf => dispatch('addBrf', brf))
							}
						}

						// Repopulate cirleSearch?
						const workflowConfig = {}
						if (workflowname === 'map/circle') {
							if (configData.resultLimit) {
								workflowConfig.resultLimit = configData.resultLimit
							}
						}
						await dispatch('setWorkflow', { workflow, workflowConfig })
					}

					return true
				} else {
					throw new Error('Could not verify apiToken')
				}
			} else {
				throw new Error('Malformed apiToken')
			}
		} catch (error) {
			console.error(error)
			return false
		}
	},
	giveConsent({ commit }, params) {
		commit(GIVE_CONSENT, params)
	},
	setWorkflow({ commit }, { workflow, workflowConfig }) {
		commit(SET_WORKFLOW, { workflow, workflowConfig })
	},
	setAlert({ commit, dispatch, state }, { i18n, raw, timeout }) {
		commit(SET_ALERT, { i18n, raw })
		if (timeout !== 0) {
			setTimeout(() => dispatch('unsetAlert'), timeout || state.alert.defaultTimeout)
		}
	},
	unsetAlert({ commit }) {
		commit(UNSET_ALERT)
	},
	saveTargetAudienceSettings(
		{ commit, state, dispatch },
		{
			age,
			gender,
			buildingTypes,
			roomCount,
			livingArea,
			buildingYear,
			phaseOfLife,
			purchasingPower,
			income,
			education,
			resultLimit,
			includePhoneNumbers,
		},
	) {
		const { targetAudience } = state
		const isDefined = (prop) => typeof prop !== 'undefined'
		const objDiffInPart = (a, b) => !!Object.entries(a).find(([key, val]) => b[key] !== val)
		const arrayDiff = (a, b) => !!(difference(a, b).length || difference(b, a).length)
		const xPropDiff = (a, b) => a.apply !== b.apply || arrayDiff(a.values, b.values)

		const settings = {
			...isDefined(age) && objDiffInPart(age, targetAudience.age.value) && { age },
			...isDefined(buildingTypes) && arrayDiff(buildingTypes, targetAudience.buildingTypes.values) && { buildingTypes },
			...isDefined(gender) && gender !== targetAudience.gender.value && { gender },
			...isDefined(roomCount) && objDiffInPart(roomCount, targetAudience.roomCount.value) && { roomCount },
			...isDefined(livingArea) && objDiffInPart(livingArea, targetAudience.livingArea.value) && { livingArea },
			...isDefined(buildingYear) && objDiffInPart(buildingYear, targetAudience.buildingYear.value) && { buildingYear },
			...isDefined(phaseOfLife) && xPropDiff(phaseOfLife, targetAudience.phaseOfLife.value) && { phaseOfLife },
			...isDefined(purchasingPower) && xPropDiff(purchasingPower, targetAudience.purchasingPower.value) && { purchasingPower },
			...isDefined(income) && xPropDiff(income, targetAudience.income.value) && { income },
			...isDefined(education) && xPropDiff(education, targetAudience.education.value) && { education },
			...isDefined(includePhoneNumbers) && includePhoneNumbers !== targetAudience.includePhoneNumbers.value && { includePhoneNumbers },
			...isDefined(resultLimit) && resultLimit !== targetAudience.resultLimit.value && { resultLimit },
		}
		if (!isEmpty(settings)) {
			dispatch('resetSearchResults')
			commit(SAVE_TARGET_AUDIENCE_SETTINGS, settings)
		}
	},
	async submitBooking({ dispatch, getters }) {
		// setBooking is used for showing the user the correct text if booking is slow
		dispatch('setBooking', { isBooking: true })
		dispatch('setBusy', { busy: true })

		const apiToken = getters.getApiToken
		const params = getters.getFinalBookingData

		try {
			await processBooking({ apiToken, params })

			dispatch('setBusy', { busy: false })
			window.parent[0].parent[0].reloadPageDRMap()
			window.parent.document.querySelector('.modal-backdrop').dispatchEvent(new Event('click'))
		} catch (e) {
			dispatch('setAlert', {
				i18n: {
					title: 'Alerts.Alert',
				},
				raw: {
					message: e.message,
				},
			})
		} finally {
			dispatch('setBooking', { isBooking: false })
			dispatch('setBusy', { busy: false })
		}
	},
	updateOrderReference({ commit }, params) {
		commit(UPDATE_ORDER_REFERENCE, params)
	},
	setDeadlineDateParams({ commit }, params) {
		commit(SET_DEADLINE_DATE_PARAMS, params)
	},
	setRefCopy({ commit }, setRefCopy) {
		commit(SET_REFCOPY, setRefCopy)
	},
	setFileUpload({ commit }, { format, addresses, originalFileName }) {
		const cityMailCount = addresses.reduce((count, line) => {
			let nextCount = count
			line.forEach(col => {
				if (col.citymail) {
					nextCount += 1
				}
			})
			return nextCount
		}, 0)
		commit(SET_FILEUPLOAD, { format, addresses, cityMailCount, originalFileName })
	},
	async placeHolidayHomesOrder({ dispatch, state }, params) {
		dispatch('setBusy', { busy: true })
		try {
			const apiToken = state.session.apiToken
			const { status } = await processHolidayHomeOrder({ apiToken, params })
			if (status === 200) {
				window.parent[0].parent[0].reloadPage()
				const backdrop = window.parent.document.querySelector('.modal-backdrop')

				if (backdrop) backdrop.dispatchEvent(new Event('click'))
			}
		} catch (e) {
			dispatch('setAlert', {
				raw: {
					message: e.message,
				},
			})
		} finally {
			dispatch('setBusy', { busy: false })
		}
	},
	async submitTelemarketingSelection({ dispatch, getters, state }) {
		try {
			dispatch('setBusy', { busy: true })
			const apiToken = getters.getApiToken
			const bookingData = getters.getFinalBookingData
			const { status } = await processTelemarketingOrder({ apiToken, bookingData })

			if (status === 200) {
				const { objectId } = state.session
				top.window.frames.mainFrame.showModal(`phone_item.asp?tblOBJECTS_id=${objectId}`)
				top.window.frames.mainFrame.closePopup()
			}
		} catch (e) {
			dispatch('setAlert', {
				i18n: { title: 'Alerts.Alert' },
				raw: {
					message: e.message,
				},
			})
		} finally {
			dispatch('setBusy', { setBusy: false })
		}
	},

	setVariant({ commit, dispatch }, variant) {
		if (variant === variants.TELEMARKETING) {
			dispatch('saveTargetAudienceSettings', { includePhoneNumbers: true })
		}
		return commit(SET_VARIANT, variant)
	},
	resetOrderData({ commit }) {
		return commit(RESET_ORDER_DATA)
	},
}
