import axios from 'axios'

// The timeout in DRMap and DRApi needs to be the same
const TIMEOUT = 1000 * 60 * 2

const getClient = ({ apiToken, cancelToken }) => axios.create({
	timeout: TIMEOUT,
	headers: {
		Authorization: `Bearer ${apiToken}`,
	},
	cancelToken,
})

export default getClient
