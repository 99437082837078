import { getAxiosClient } from '../httpClient'

const serviceURL = process.env.VUE_APP_MAPAPI_URL

export async function searchAreas(apiToken, params, cancelToken) {
	const { data } = await getAxiosClient({ apiToken, cancelToken }).post(
		`${serviceURL}/search/area/street-addresses`,
		JSON.stringify(params),
		{ headers: { 'Content-Type': 'application/json' } },
	)
	return data
}

export async function fetchStreetAddresses(apiToken, params, cancelToken) {
	const { data } = await getAxiosClient({ apiToken, cancelToken }).post(
		`${serviceURL}/search/area/street-addresses`,
		JSON.stringify(params),
		{ headers: { 'Content-Type': 'application/json' } },
	)
	return data
}

export async function searchCircle(apiToken, params, cancelToken) {
	const { data } = await getAxiosClient({ apiToken, cancelToken }).post(
		`${serviceURL}/search/circle/street-addresses`,
		JSON.stringify(params),
		{ headers: { 'Content-Type': 'application/json' } },
	)
	return data
}

export async function searchZip(apiToken, params, cancelToken) {
	const { data } = await getAxiosClient({ apiToken, cancelToken }).post(
		`${serviceURL}/search/zip/street-addresses`,
		JSON.stringify(params),
		{ headers: { 'Content-Type': 'application/json' } },
	)
	return data
}

export async function searchBrfs(apiToken, {
	orderIdentification,
	soapAction,
	brfIds,
}, cancelToken) {
	const { data } = await getAxiosClient({ apiToken, cancelToken }).post(
		`${serviceURL}/search/brf/street-addresses`,
		JSON.stringify({ orderIdentification, soapAction, brfIds }),
		{
			headers: { 'Content-Type': 'application/json' },
		},
	)
	return data
}