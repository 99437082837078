<template>
  <div>
    <Modal :open="open" @close="close">
      <template slot="title">
        <slot name="title"></slot>
      </template>
      <Markdown :markdown="markdownText" />
      <div v-if="type === 'confirm'" class="buttons">
        <v-btn color="secondary" @click="$emit('close', -1)">{{$t('Cancel')}}</v-btn>
        <v-btn color="primary" @click="$emit('close', 1)">{{$t('Ok')}}</v-btn>
      </div>
    </Modal>
    <span
      class="pr-3 info-pill text-body-2"
      :title="tooltip"
      @click="$emit('open')" >
    </span>
  </div>
</template>

<script>
import { Markdown } from '../Markdown'
import Modal from './Modal.vue'
import { getMarkdown } from '../../../markdown'

export default {
	name: 'MarkdownModal',
	props: ['tooltip', 'open', 'markdown', 'type'],
	components: {
		Modal,
		Markdown,
	},
	data: () => ({
		markdownText: '',
	}),
	computed: {
		location() {
			return this.$store.state.location
		},
	},
	methods: {
		close(result = 0) {
			this.$emit('close', { result })
		},
		async getMarkdownText() {
			const markdownText = await getMarkdown(this.location, this.markdown)
			this.markdownText = markdownText
		},
	},
	async mounted() {
		await this.getMarkdownText()
	},
}
</script>

<style scoped lang="scss">
@import "./MarkdownModal.scss";
</style>