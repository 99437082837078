import { MAPS_INIT_IDLE } from './constants'

const { VUE_APP_MAPAPI_KEY } = process.env

export default {
	map: null,
	mapNode: null,
	markers: {
		search: null,
		circle: null,
	},
	status: {
		loadState: MAPS_INIT_IDLE,
		message: false,
	},
	config: {
		url: 'https://maps.googleapis.com/maps/api/js',
		key: VUE_APP_MAPAPI_KEY,
		params: {
			libraries: 'visualization',
			language: navigator.language || 'sv',
		},
	},
	location: {
		latLng: {
			lat: 55.587379,
			lng: 13.004312,
		},
		addressSearch: {
			address: '',
			error: '',
		},
	},
	// colors: ['#02B5A0', '#954567', '#f37252', '#0180b5', '#ea3e70', '#ff8201', '#4bc4d5', '#c72c3a', '#ffd35c', '#8a9747'],
	colors: [
		{ r: 2, g: 181, b: 160 },
		{ r: 149, g: 69, b: 103 },
		{ r: 243, g: 114, b: 82 },
		{ r: 1, g: 128, b: 181 },
		{ r: 234, g: 62, b: 112 },
		{ r: 255, g: 130, b: 1 },
		{ r: 75, g: 196, b: 213 },
		{ r: 199, g: 44, b: 58 },
		{ r: 255, g: 211, b: 92 },
		{ r: 138, g: 151, b: 71 },
	],
	// TODO: Move all results to searchResult instead
	circleSearch: {
		location: null, // { lat, lng }
		address: null,
		addresses: [],
		excludeList: [],
		coordinates: [],
		count: 0,
		heatmap: null,
	},
	brfSearch: {
		selected: [],
		saved: [],
	},
	zipSearch: {
		zipRanges: [],
	},
	searchResult: {
		addresses: [],
		excludeList: [],
		coordinates: [],
		count: 0,
		heatmap: null,
	},
	listeners: [],
	polygons: [],
	polygonCount: 0,
}
