import {
	BUILDING_TYPE_DEFAULT,
	BUILDING_TYPE_TENANCY,
	BUILDING_TYPE_CONDO,
	BUILDING_TYPE_HOUSE,
	BUILDING_TYPE_COMBO,
} from './constants'

const intersects = (a, b, c, d, p, q, r, s) => {
	const det = (c - a) * (s - q) - (r - p) * (d - b)
	if (det === 0) {
		return false
	}
	const lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det
	const gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det
	return (lambda > 0 && lambda < 1) && (gamma > 0 && gamma < 1)
}

// TODO: make loop short curcuit on intersection
// Can we do better than filter?
const edgesAreOk = (coords) => {
	let result = true
	if (coords.length > 2) {
		coords
			.forEach((v1, i, vertices) => {
				// const remaining = vertices.filter((v) => v !== v1);
				const remaining = vertices.slice()
				remaining.splice(i, 1)
				const v2 = vertices[((i + 1) % vertices.length)]
				remaining.forEach((v3, j) => {
					const v4 = remaining[(j + 1) % remaining.length]
					if (intersects(
						v1.lat, v1.lng, v2.lat, v2.lng, v3.lat, v3.lng, v4.lat, v4.lng,
					)) {
						result = false
					}
				})
			})
	}
	return result
}

const latLng2coord = ({ lat, lng }) => ({ lat: lat(), lng: lng() })

const path2Array = (path) => path.getArray().map(latLng2coord)

export const polygonPathIsCompliant = (path) => edgesAreOk(path2Array(path))

export const addVertex = (polygon, latLng) => {
	const { gPolygon } = polygon
	gPolygon.getPath().push(latLng)
}

export const getVertexRemover = ({ gPolygon }) => (evt) => {
	if (evt.vertex) {
		gPolygon.getPath().removeAt(evt.vertex)
	}
}

export const getPolygonCenter = ({ gPolygon }) => {
	const path = gPolygon.getPath().getArray()
	return ((path.length !== 0)
		? path.reduce((ack, item, index, arr) => (index === arr.length - 1
			? {
				lat: (ack.lat + item.lat()) / arr.length,
				lng: (ack.lng + item.lng()) / arr.length,
			}
			: {
				lat: ack.lat + item.lat(),
				lng: ack.lng + item.lng(),
			}), { lat: 0, lng: 0 })
		: null)
}

export const buildingTypes2areaType = (str) => {
	try {
		if (str.length === 0) {
			return BUILDING_TYPE_DEFAULT
		} if (str.includes(`${1504}`) && str.includes(`${30}`)) {
			return BUILDING_TYPE_COMBO
		} if (str.includes(`${30}`)) {
			return BUILDING_TYPE_HOUSE
		} if (str.includes(`${1504}`) || str.includes(`${3552}`)) {
			return BUILDING_TYPE_CONDO
		} if (str.includes(`${512}`)) {
			return BUILDING_TYPE_TENANCY
		}
	} catch (e) {
		return BUILDING_TYPE_DEFAULT
	}
	return BUILDING_TYPE_DEFAULT
}

const streetNumberComponentToProps = (streetNumberComponent) => {
	// Example of return {streetNumberFrom: '38', streetNumberTo: '38', streetSuffix: 'A'}
	// TODO: Sanity check, whi can this return streetNumberTo?
	const [partFrom, partTo] = streetNumberComponent.long_name.split('-').map(part => part.trim())
	const [numberFrom, suffixFrom] = [
		(/^([0-9]+)/.exec(partFrom) || [])[0],
		(/([A-ZÅÄÖ]+)$/i.exec(partFrom) || [])[0],
	]
	const numberTo = (/^([0-9]+)/.exec(partTo) || [])[0]
	const streetNumberFrom = { streetNumberFrom: numberFrom || '' }
	const streetNumberTo = { streetNumberTo: numberTo || numberFrom || '' }
	const streetSuffix = { streetSuffix: suffixFrom || '' }

	return { ...streetNumberFrom, ...streetNumberTo, ...streetSuffix }
}

export const reverseGeocodeResultToParams = (reverseGeocodeResult, searchAddress) => {
	// Filter out similar addresses based on "formatted_addresss"
	const tmpMap = new Map(reverseGeocodeResult.map(item => [item.formatted_address, item]))
	let uniqueAddresses = [...tmpMap.values()]
	// address_components with fewer than four elements are most likely not valid addresses
	uniqueAddresses = uniqueAddresses.filter((item) => item.address_components.length > 4)

	let foundIndex = 0
	if (uniqueAddresses.length !== 0) {
		// Loop through filtered addresses, pick first similar address. If nothing is found, we default to 0
		const tmpSearchAddress = searchAddress.split(' ')[0].toUpperCase()
		for (let i = 0; i < uniqueAddresses.length; i += 1) {
			if (uniqueAddresses[i].formatted_address.toUpperCase().indexOf(tmpSearchAddress) === 0) {
				foundIndex = i
				break
			}
		}
	}

	// This now uses index for "best guess"
	const {
		address_components: addressComponents,
	} = reverseGeocodeResult[foundIndex]

	// Formatting address
	const params = addressComponents.reduce((ack, component) => {
		// Street number can be of type 'street_number' or 'premise', depending on the returned result
		if (component.types.includes('street_number')) {
			return { ...ack, ...streetNumberComponentToProps(component) }
		}
		if (component.types.includes('premise')) {
			return { ...ack, ...streetNumberComponentToProps(component) }
		}
		// The street name can be of the type 'route' or 'locality'.
		// It can also be of type 'political', but 'political' is included as a type
		// for MANY of the address components and is therefore not added here
		if (component.types.includes('route')) {
			return { ...ack, streetName: component.long_name }
		}
		// Some addreses have their street names located under the combined component types ['locality', 'political']
		// example address: Ullarp 123, 311 69 Slöinge
		if (component.types.includes('locality') && component.types.includes('political')) {
			return { ...ack, streetName: component.long_name }
		}
		if (component.types.includes('locality')) {
			return { ...ack, city: component.long_name }
		}
		if (component.types.includes('postal_code')) {
			return { ...ack, zip: component.long_name }
		}
		if (component.types.includes('postal_town')) {
			return { ...ack, city: component.long_name }
		}
		return ack
	}, {
		streetNumberType: 'All',
		zipType: 'single',
	})
	return {
		...params,
	}
}

export const isValidReverseGeocodeResult = (reverseGeocodeResult) => {
	const {
		address_components: addressComponents,
	} = reverseGeocodeResult[0]
	// In the returned results, the address component which contains the street number can be returned as type 'street_number' or type 'premise'
	// Example: Searching for the adress "Almvägen 2, Tullinge" returns an address_component of type 'steet_number'. Searching for the
	// address "Huggning 104, Älandsbro" returns an address_component of type 'premise'.
	// TODO: why is street number required for the address to be considered valid?
	return !!addressComponents.find(component => component.types.includes('street_number') || component.types.includes('premise'))
}