import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../views/Start.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'AdressSelection',
		component: Start,
	},
	{
		path: '/map',
		name: 'MapSelection',
		component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
	},
	{
		path: '/map/:workflow',
		name: 'MapSelectionPreset',
		component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
	},
	{
		path: '/fileupload',
		name: 'FileSelection',
		component: () => import(/* webpackChunkName: "about" */ '../views/FileUpload.vue'),
	},
	{
		path: '/holidayhomes',
		name: 'HolidayHomeSelection',
		component: () => import(/* webpackChunkName: "about" */ '../views/HolidayHomes.vue'),
	},
	{
		path: '/distribution',
		name: 'Distribution',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/Distribution.vue'),
	},
	{
		path: '/unauthorized',
		name: 'Unauthorized',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/Unauthorized.vue'),
	},
]

const router = new VueRouter({
	routes,
	mode: 'history',
})

export default router
